import CateringHelper from "./CateringHelper";
import { ErrorBoundary } from "react-error-boundary";
import CheckoutFinalizeQuantities from "./CheckoutFinalizeQuantities";
import CheckoutPayment from "./CheckoutPayment";
import MenuPage from "./MenuPage";
import OrderModification from "./OrderModification";
import OrderSummary from "./OrderSummary";
import React, { useState } from "react";
import CheckoutSteps, { CheckoutStep, CheckoutStepStatus } from "./CheckoutSteps";
import { useSession } from './SessionContext'; 
import { Order, OrderStatus } from 'src/graphql/generated-types';
// navigate function props
export interface CateringHelperShellProps {
  navigateFunction: (path: string) => void;
}

function CateringHelperShell() {
  console.log("Rendering CateringHelperShell");
  const components: Map<string, React.FunctionComponentElement<CateringHelperShellProps>> = new Map();
  const { session, setSession } = useSession();
  const addComponentToMap = (path: string, component: React.FC<CateringHelperShellProps>) => {
    components.set(path, React.createElement(component, commonProps));
  }
  function resetSession(): void {
    const url = new URL(window.location.origin + window.location.pathname);
    console.log('url:', url);
    window.history.pushState({}, '', url);
    window.location.reload();
  }

  const navigateFunction = (path: string) => {
    if (session?.order?.state === OrderStatus.Completed) {
      resetSession();
    }
    console.log("navigateFunction called with path: " + path);

    // set all checkout steps to upcoming unless disabled
    steps.forEach(step => {
      if (step.status != CheckoutStepStatus.Disabled) {
        step.status = CheckoutStepStatus.Upcoming;
      }

    });

    // set navigation step to current
    let navStep = steps.find(step => step.navigationLocation === path);
    if (navStep) {
      navStep.status = CheckoutStepStatus.Current;
      setCurrentStep(navStep);
    }
    setActiveComponent(() => components.get(path));
  }

  let commonProps = {
    navigateFunction: navigateFunction
  };

  console.log("Setting up components map");
  addComponentToMap("cathelper", CateringHelper);
  addComponentToMap("menu", MenuPage);
  addComponentToMap("order_modification", OrderModification);
  addComponentToMap("checkout_finalize_quants", CheckoutFinalizeQuantities);
  addComponentToMap("checkout_payment", CheckoutPayment);
  addComponentToMap("order_summary", OrderSummary);

  let temp = components.get("cathelper");
  console.log("retreived component from map: ", temp);
  const [activeComponent, setActiveComponent] = useState(() => components.get("cathelper"));
  console.log("Rendering ActiveCateringHelperComponent with navigateFunction:", navigateFunction);
  let idx = 1;

  let stepArray: Array<CheckoutStep> = [
    {
      id: idx++,
      name: "Begin Order",
      navigationLocation: "cathelper",
      status: CheckoutStepStatus.Upcoming
    },
    {
      id: idx++,
      name: "Add Ons",
      navigationLocation: "menu",
      status: CheckoutStepStatus.Upcoming
    },
    {
      id: idx++,
      name: "Finalize Quantities",
      navigationLocation: "checkout_finalize_quants",
      status: CheckoutStepStatus.Upcoming
    },
    {
      id: idx++,
      name: "Payment",
      navigationLocation: "checkout_payment",
      status: CheckoutStepStatus.Upcoming
    },
    {
      id: idx++,
      name: "Order Summary",
      navigationLocation: "order_summary",
      status: CheckoutStepStatus.Disabled
    }
  ]
  const [steps, setSteps] = useState<Array<CheckoutStep>>(stepArray);
  // let steps: Array<CheckoutStep> = 

  const [currrentStep, setCurrentStep] = useState(steps[0]);
  // set the current step in steps based on currentStep
  steps.forEach(step => {
    if (step.id === currrentStep.id) {
      step.status = CheckoutStepStatus.Current;
    } else {
      // step.status = CheckoutStepStatus.Upcoming;
    }
  });

  return (
    <div className="bg-white relative">
      <div className="fixed z-10 pb-2 bg-white w-full  flex justify-center inset-x-0">
        <div className="w-[900px] bg-white ">
        <CheckoutSteps steps={steps} navigateFunction={navigateFunction} />
        </div>
      </div>
      <div className="pt-20">
        <ErrorBoundary fallback={<div>Something went wrong</div>}>
          {activeComponent}
        </ErrorBoundary>
      </div>
    </div>
  )
}

export default CateringHelperShell




