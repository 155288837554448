// components/DietaryPreferences.tsx
import React, { useState } from 'react';

const DietaryPreferencesComponent: React.FC = () => {
  const [preferences, setPreferences] = useState({
    vegetarian: 0,
    vegan: 0,
    glutenFree: 0,
  });

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setPreferences(prevState => ({
      ...prevState,
      [name]: Math.max(0, parseInt(value, 10) || 0), // Ensure count does not go below 0 and handle non-numeric inputs gracefully
    }));
  };

  return (
    <div className="flex flex-col gap-3">
      {Object.entries(preferences).map(([name, count]) => (
        <div key={name} className="flex items-center">
          <label className="block text-sm font-medium text-gray-700 w-28">
            {name.charAt(0).toUpperCase() + name.slice(1)}:
          </label>
          <input
            type="number"
            name={name}
            value={count}
            onChange={handleInputChange}
            className="mt-1 block pl-2 pr-2 py-2 border w-20zx border-gray-300 bg-white text-sm rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
            min="0"
          />
        </div>
      ))}
    </div>
  );
};

export default DietaryPreferencesComponent;
