// A static class for string manipulation
export class StringUtils {
    // Returns true if the string is null, undefined, or empty
    public static isNullOrEmpty(str: string | null | undefined): boolean {
        return str === null || str === undefined || str === "";
    }

    // Title cases a string
    public static toTitleCase(str: string): string {
        return str.replace(
            /\w\S*/g,
            (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
        );
    }
}