// components/MealTypeComponent.tsx
import React, { useEffect, useState } from 'react';
import lunchImage from '../../assets/lunch.jpg';  // Please replace with your actual image path
import dinnerImage from '../../assets/dinner.jpg'; // Please replace with your actual image path
import { ImageContainer, StyledButton, ButtonText } from './StyledComponents';
import { Order, MealType } from 'src/graphql/generated-types';
import { useSession } from './SessionContext';
// enum MealTypes {
//   LUNCH = 'lunch',
//   DINNER = 'dinner',
// }
type mealTimeProps = {
  onValueChange: (value: MealType) => void;
};

const MealTimeComponent: React.FC<mealTimeProps> = ({onValueChange}) => {
  const [mealType, setMealType] = useState<MealType | string>('');
  const { session, setSession } = useSession();

  // select appropriate meal time based on session.order.mealTime in a useEffect hook
  useEffect(() => {
    if (session?.order?.mealType) {
      handleMealSelection(session.order.mealType);
    }
  }, [session?.order?.mealType]);

  const handleMealSelection = (meal: MealType) => {
    setMealType(meal);
    onValueChange(meal);
    if (session) {
      const updatedOrder = {  
        ...session.order,
        mealType: meal
      };
      setSession({
        ...session,
        order: updatedOrder as Order
      });
    }
  };

  return (
    <div>
      {/* <h4>Your Selected meal type: {mealType}</h4> */}
      <ImageContainer>
        <StyledButton 
          onClick={() => handleMealSelection(MealType.Lunch)} 
          style={{
            backgroundImage: `url(${lunchImage})`,
            border: mealType === MealType.Lunch? '8px solid orange' : 'none'
          }}
        >
          <ButtonText>Lunch</ButtonText>
        </StyledButton>
      </ImageContainer>

      <ImageContainer>
        <StyledButton 
          onClick={() => handleMealSelection(MealType.Dinner)} 
          style={{
            backgroundImage: `url(${dinnerImage})`,
            border: mealType === MealType.Dinner ? '8px solid orange' : 'none'
          }}
        >
          <ButtonText>Dinner</ButtonText>
        </StyledButton>
      </ImageContainer>
    </div>
  );
};

export default MealTimeComponent;
