"use strict";
exports.__esModule = true;
exports.LocationInfo = void 0;
exports.LocationInfo = {
    name: "Spicy Roy's Caribbean Grill",
    logoUrl: 'https://via.placeholder.com/150',
    url: 'https://www.spicyroysgrill.com',
    email: 'catering@spicyroysgrill.com',
    phoneNumber: '(408)459-9055',
    salesTaxRate: 0.09375,
    address: {
        street: '856 N 13th St',
        city: 'San Jose',
        province: 'CA',
        postalCode: '95112'
    },
    maxBoxesPerMealtime: 200,
    maxBuffetHeadcountPerMealtime: 350
};
