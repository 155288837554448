import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import veganIcon from "../../assets/vegan_icon.png"
import vegetarianIcon from "../../assets/vegetarian_icon.png"
import glutenFreeIcon from "../../assets/gluten_free.svg"
import { ArrowsRightLeftIcon } from '@heroicons/react/24/outline'
import { MenuItem } from 'src/graphql/generated-types';
import { MenuItemPlusDietary } from './MenuItemPlusDietary'
interface SwapOrderDetailsDialogProps {
  nameOfMenuItemToSwapOut: string;
  menuItemList: MenuItem[] | undefined;
  onClose: () => void;
  onSelectItemToSwapIn: (menuItem: MenuItem) => void;
}
export default function SwapOrderDetailsDialog({ menuItemList, nameOfMenuItemToSwapOut, onSelectItemToSwapIn, onClose }: SwapOrderDetailsDialogProps) {
  const [open, setOpen] = useState(true)

  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="z-10" initialFocus={cancelButtonRef} onClose={() => onClose()}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen">
          <div className="flex items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div>
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <ArrowsRightLeftIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                      Swap Out <i className='text-gray-500'>{nameOfMenuItemToSwapOut}</i> for...
                    </Dialog.Title>
                    <div id="menuItems" className="mt-2 max-h-[50vh] overflow-y-auto">
                      {menuItemList?.map((menuItem) => (
                        <div className="flex flex-row justify-between">
                          <div className="flex flex-col">
                            <div className="flex flex-row items-center text-sm text-gray-900" onClick={() => {
                              onSelectItemToSwapIn(menuItem);
                              onClose();
                            }}>
                              <MenuItemPlusDietary menuItem={menuItem} />
                            </div>
                          </div>
                          <div className="text-sm font-semibold text-gray-900">{menuItem.price}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 flex justify-center">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:max-w-xs"
                      onClick={() => onClose()}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>

  )
}
