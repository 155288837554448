import React from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";

const CalendarTest: React.FC = () => { 
    const handleEventClick = (clickInfo: any) => {
        alert(clickInfo.event.title);
    };

    return (
        <div className='p-4'>
            <FullCalendar
                plugins={[dayGridPlugin, interactionPlugin]}
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridDay,dayGridWeek,dayGridMonth'
                }}
                initialView="dayGridWeek"
                events={[
                    { title: 'Event 1', date: '2023-11-18' },
                    { title: 'Event 2', date: '2023-11-19' }
                ]}
                eventClick={handleEventClick}

                // When date is clicked, view that date in dayGridDay view
                dateClick={(info) => {
                    console.log('dateClick', info);
                    info.view.calendar.changeView('dayGridDay', info.dateStr);
                }}
            />
        </div>
    );
};

export default CalendarTest;
