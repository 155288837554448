import React, { createContext, useContext } from 'react';
import { ILocationInfo } from 'shared/ILocationInfo';
import { LocationInfo }  from 'shared/LocationInfo';
interface BusinessContextProps { 
    brandInfo: ILocationInfo 
} 

const BusinessContext = createContext<BusinessContextProps | undefined>(undefined);

export const BusinessProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => { 
    const businessContextValue: BusinessContextProps = {
        brandInfo : LocationInfo
    };

    return (
        <BusinessContext.Provider value={businessContextValue}>
        {children}
        </BusinessContext.Provider>
    );
  };

export const useBusinessContext = () => {
    const context = useContext(BusinessContext);
    if (context === undefined) {
        throw new Error('useBusinessContext must be used within a BusinessProvider');
    }
    return context;
}