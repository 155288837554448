import React, { useState } from 'react';

interface CreditCardFormProps {
  onSubmit: (billingInfo: BillingInfo) => void;
}

export interface BillingInfo {
  name: string;
  streetAddress: string;
  suite: string;
  city: string;
  state: string;
  zipCode: string;
  email: string;
  phone: string;
  cardNumber: string;
  cardExpirationMonth: string;
  cardExpirationYear: string;
  cardCVC: string;
  cardName: string;
}

const BillingInfoCreditCard: React.FC<CreditCardFormProps> = ({ onSubmit }) => {
  const [billingInfo, setBillingInfo] = useState<BillingInfo>({
    name: '',
    streetAddress: '',
    suite: '',
    city: '',
    state: '',
    zipCode: '',
    email: '',
    phone: '',
    cardNumber: '',
    cardExpirationMonth: '',
    cardExpirationYear: '',
    cardCVC: '',
    cardName: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setBillingInfo(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit(billingInfo);
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-xl mx-auto my-4 p-6 bg-white rounded-md shadow-md">
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3 mb-6 md:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="name">
            Name
          </label>
          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white" id="name" type="text" name="name" onChange={handleChange} required />
        </div>
        <div className="w-full md:w-2/3 px-3 mb-6 md:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="streetAddress">
            Street Address
          </label>
          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="streetAddress" type="text" name="streetAddress" onChange={handleChange} required />
        </div>
        <div className="w-full md:w-1/3 px-3">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="suite">
            Suite
          </label>
          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="suite" type="text" name="suite" onChange={handleChange} />
        </div>
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="city">
            City
          </label>
          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="city" type="text" name="city" onChange={handleChange} required />
        </div>
        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="state">
            State
          </label>
          <div className="relative">
            <select className="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="state" name="state" onChange={handleChange} required>
              {/* Populate with your states */}
              <option>New York</option>
              <option>California</option>
              {/* ... */}
            </select>
          </div>
        </div>
        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="zipCode">
            Zip Code
          </label>
          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="zipCode" type="text" name="zipCode" onChange={handleChange} required />
        </div>
        <div className="w-full px-3">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="email">
            Email Address
          </label>
          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="email" type="email" name="email" onChange={handleChange} required />
        </div>
        <div className="w-full px-3">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="phone">
            Phone Number
          </label>
          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="phone" type="tel" name="phone" onChange={handleChange} required />
        </div>
        <div className="w-full px-3">
          <label className="flex items-center mt-3">
            <input type="checkbox" className="form-checkbox h-5 w-5 text-gray-600" /><span className="ml-2 text-gray-700 text-sm">Email me promotions</span>
          </label>
        </div>
      </div>
      <div className="flex flex-wrap -mx-3 mb-6">
        <div className="w-full px-3">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="cardNumber">
            Credit Card Number
          </label>
          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="cardNumber" type="text" name="cardNumber" onChange={handleChange} required />
        </div>
        <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="cardExpirationMonth">
            Expiration Date (MM/YY)
          </label>
          <div className="flex justify-start">
            <input className="appearance-none block w-16 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="cardExpirationMonth" type="text" name="cardExpirationMonth" onChange={handleChange} placeholder="MM" required />
            <span className="mx-2 mt-3 text-gray-700">/</span>
            <input className="appearance-none block w-16 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="cardExpirationYear" type="text" name="cardExpirationYear" onChange={handleChange} placeholder="YY" required />
          </div>
        </div>
        <div className="w-full md:w-1/2 px-3">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="cardCVC">
            CVC
          </label>
          <input className="appearance-none block w-16 bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="cardCVC" type="text" name="cardCVC" onChange={handleChange} required />
        </div>
        <div className="w-full px-3">
          <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="cardName">
            Name on Card
          </label>
          <input className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" id="cardName" type="text" name="cardName" onChange={handleChange} required />
        </div>
      </div>
      <div className="flex items-center justify-between">
        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="submit">
          Add new payment
        </button>
        <button className="inline-block align-baseline font-bold text-sm text-blue-500 hover:text-blue-800" type="button">
          Cancel
        </button>
      </div>
    </form>
  );
};

export default BillingInfoCreditCard;
