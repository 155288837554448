import { FulfillmentMethod, MenuItem, Session } from 'src/graphql/generated-types';
import { useSession } from './SessionContext'; // Adjust the path as necessary
import { StringUtils } from 'src/utilities/StringUtils';
import { NumberUtils } from 'src/utilities/NumberUtils';
import { useBusinessContext } from '../BusinessContext';

function OrderSummary() {
  const { session, setSession } = useSession();
  const { brandInfo } = useBusinessContext(); 
  const readableFulfillmentMethod = StringUtils.toTitleCase(session?.order?.fulfillmentMethod || '');
  const friendlyDate = session?.order?.pickupOrDeliveryDateTime ? new Date(session.order.pickupOrDeliveryDateTime).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }) : '';
  const friendlyTime = session?.order?.pickupOrDeliveryDateTime ? new Date(session.order.pickupOrDeliveryDateTime).toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  }) : '';
  return (
    <div className="p-2 m-2">
      <div className='flex flex-col items-center'>
        <div><h3 className='font-bold'>Order Successful, Thank You!</h3></div>
        <div className='flex items-center'><h4>Order # </h4><div className='ml-2'>  {session?.order?.id}</div></div>
      </div>

      <div className="flex flex-col md:flex-row px-2  justify-center items-start p-4 border-b">
        {/* column 1 - Fulfillment Details */}
        <div className='w-full md:w-96  border-2 rounded-md mx-2 mb-2 p-2'>
          <h4 className='font-bold flex justify-center  bg-yellow-300'>Pickup Details</h4>
          <h5>{readableFulfillmentMethod} Date</h5>
          {friendlyDate}
          <h5>{readableFulfillmentMethod} Time</h5>
          {friendlyTime}

          <h5>Pickup Address</h5>
          <div className=''>Spicy Roy's Caribbean Grill</div>
          <div className=''>{brandInfo.address.street}</div>
          <div className=''>{brandInfo.address.city}, {brandInfo.address.province} {brandInfo.address.postalCode}</div>
          <div className=''>{brandInfo.email}</div>
          <div className=''>{brandInfo.phoneNumber}</div>
          {session?.order?.fulfillmentMethod === FulfillmentMethod.Delivery && <h4>Delivery charge</h4>}
        </div>
        {/* column 2 */}
        <div className='flex flex-col'>
          <div className="w-full md:w-96  border-2 rounded-md mb-2 mx-2 p-2">
            <h4 className='font-bold flex justify-center  bg-yellow-300'>Ordered Items</h4>
            <div >
              {session?.order?.menuItems?.map(item => (
                <div key={item.squareItemId} className="flex justify-between p-1 border-b">
                  <div className="pr-4">
                    <span className="font-bold">{item.quantity}x</span> {item.name}
                  </div>
                  <div>
                    {`$${parseFloat((item.quantity * item.price).toString()).toFixed(2)}`}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="w-full md:w-96  border-2 rounded-md mb-2 mx-2 p-2">
            <h4 className='font-bold flex justify-center bg-yellow-300'>Additional Details</h4>
            <div >
              <div className="flex justify-between bg-pink-500">
                <div className="pr-4 ">
                  {/* This isn't really the subtotal (before tax and tip). Need to fix this */}
                  <span className="font-bold">Subtotal</span>
                </div>
                <div>
                  {NumberUtils.toDollars((session?.order?.subtotal || 0))}
                </div>
              </div>
              <div className="flex justify-between  ">
                <div className="pr-4">
                  <span className="font-bold">Tax</span>
                </div>
                <div>
                  {NumberUtils.toDollars(session?.order?.tax || 0)}
                </div>
              </div>
              <div className="flex justify-between ">
                <div className="pr-4">
                  <span className="font-bold">Tip</span>
                </div>
                <div>
                  {NumberUtils.toDollars(session?.order?.tip || 0)}
                </div>
              </div>

              <div className="flex justify-between border-t border-b">
                <div className="pr-4">
                  <span className="font-bold">Total</span>
                </div>
                <div>
                  {NumberUtils.toDollars((session?.order?.total || 0))}
                </div>
              </div>

              <div>
                <div>
                  <span className="font-bold">Person Picking Up</span>
                </div>
                <div>
                  {session?.order?.recipientName && <div>{session?.order?.recipientName}</div>}
                  {session?.order?.recipientPhoneNumber && <div>{session?.order?.recipientPhoneNumber}</div>}
                  {session?.order?.recipientEmailAddress && <div>{session?.order?.recipientEmailAddress}</div>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </div>
  );
}

export default OrderSummary;
