// component/CateringHelper.tsx     
import DietaryPreferences from './DietaryPreferences'; 
import Headcount from './Headcount';
import MealTimeComponent from './MealTime';
import ServingStyleComponent from './ServingStyleComponent';
import OrderDetails from './OrderDetails';
import Cart from './Cart';
import { CateringHelperShellProps } from './CateringHelperShell';
import { useSession } from './SessionContext';
import { useEffect, useState } from 'react';
import { StringUtils } from '../../utilities/StringUtils';
import { Session, MealType, ServingStyle } from '../../graphql/generated-types';

function CateringHelper({navigateFunction} : CateringHelperShellProps)  {
  console.log("Rendering CateringHelper");
  // Use the useSession hook
  const { session, setSession } = useSession();
  const showCart = session?.order?.menuItems && session.order.menuItems.length > 0;
  const [mealTime, setMealTime] = useState('');
  const [servingStyle, setServingStyle] = useState('');
  const [headcountLabelValue, setHeadcountLabelValue] =  useState('');

  useEffect(() => {
    if (session?.order?.headcount) {
      setHeadcountLabelValue(session.order.headcount + "");
    }
  }, [session?.order?.headcount]);
  return (
    <div className="flex flex-col items-center w-full min-h-screen bg-repeat border-b-gray-800 border-3">
      <div className="bg-white p-0 w-8/12">
        {/* <h1 className="text-2xl m-0 p-2 text-center text-white bg-orange-500">Catering Helper</h1> */}
        <div className='details-container'>
          <details>
            {/* <summary>How many folks?</summary> */}
            <summary>{Number(headcountLabelValue) 
                        ? StringUtils.toTitleCase(headcountLabelValue) + (Number(headcountLabelValue) > 1? " People" : " Person") 
                        : "How many folks?"}</summary>

            <Headcount
              headcount={session?.order?.headcount || 0}
              setHeadcount={(value) => {
                setHeadcountLabelValue(value + "");
                console.log("Headcount value changed setting session.");
                setSession(prevSession => {
                  if (!prevSession) return null;
                  return {
                    ...prevSession,
                    order: {
                      ...prevSession.order,
                      headcount: value
                    }
                  } as Session;
                }
                )
              }
              }
            />
          </details>
          <details className=''>
            <summary>Meal Time: {mealTime != MealType.Unset ? StringUtils.toTitleCase(mealTime) : "Lunch or Dinner?"}</summary>
            <MealTimeComponent onValueChange={setMealTime} />
          </details>
          <details>
            <summary>Serving Style: {servingStyle != ServingStyle.Unset ? StringUtils.toTitleCase(servingStyle) : "Boxed Meals or Buffet Style?"}</summary>
            <ServingStyleComponent onValueChange={setServingStyle} />
          </details>
          <details>
            <summary>Dietary Preferences</summary>
            <DietaryPreferences />
          </details>
          <details>
            <summary>Order Details</summary>
            <OrderDetails navigateFunction={navigateFunction} />
          </details>
        </div>
      </div>
      {showCart && <Cart navigateFunction={navigateFunction} />}
    </div>
  );
}

export default CateringHelper;
