// components/CheckoutPayment.tsx
import React, { useEffect, useState } from 'react';
import { useSession } from './SessionContext';
import { useBusinessContext } from '../BusinessContext';
import { OrderStatus, Session } from 'src/graphql/generated-types';
import { useCart } from './CartContext';
import { CreditCard, GooglePay, ApplePay, PaymentForm } from 'react-square-web-payments-sdk';
import { IdentificationIcon } from '@heroicons/react/24/outline'
import { Order } from 'src/graphql/generated-types';
import CCDialog from '../Common/CCDialog';
import { COMPLETE_ORDER_PAYMENT } from '../../graphql/mutations/mutations'
import { useMutation } from '@apollo/client/react/hooks/useMutation';
import { TokenResult } from '@square/web-sdk';
import { CateringHelperShellProps } from './CateringHelperShell';
import OrderItems from './OrderItems';
import TipSelector from './TipSelector';

function CheckoutPayment({ navigateFunction }: CateringHelperShellProps) {
  const [completePayment, { error }] = useMutation(COMPLETE_ORDER_PAYMENT);
  const [isSameAsBilling, setIsSameAsBilling] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(false);
  const { cartItems } = useCart();
  const subTotal = cartItems.reduce((sum, item) => sum + (item.price) * item.quantity, 0);
  const TAX_RATE = 0.09375; // 9.375%
  const tax = subTotal * TAX_RATE;
  const [tipPercentage, setTipPercentage] = useState(0.15); // Default 15% tip
  const tip = subTotal * tipPercentage;
  const locationId = process.env.REACT_APP_SANDBOX_LOCATION || '';
  const squareApplicationId = process.env.REACT_APP_SANDBOX_APPLICATION_ID || '';
  const { session, setSession } = useSession();
  const { brandInfo } = useBusinessContext();

  const paymentMethods = [
    { id: 'card', name: 'Credit Card' },
    { id: 'googlePay', name: 'Google Pay' },
    { id: 'applePay', name: 'Apple Pay' },
    // Add other payment methods here
  ];
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(paymentMethods[0].id);

  // Function to handle the opening of the dialog
  const openDialog = () => {
    setIsDialogOpen(true);
  };
  useEffect(() => {
    if (error) {
      console.error("COMPLETE_ORDER_PAYMENT Mutation error:", error);
    }
  }, [error]);


  useEffect(() => {
    setSession(prevSession => {
      if (!prevSession) return null;
      return {
        ...prevSession,
        order: {
          ...prevSession.order,
          subtotal: subTotal,
          tip: tip,
          tax: tax,
          total: subTotal + tax + tip
        }
      } as Session;
    });
  }, [tip, tax, subTotal, setSession]);

  const updateRecipientDetails = (field: keyof Order, value: string) => {
    if (session && session.order) {
      const updatedOrder = { ...session.order, [field]: value };
      const updatedSession = { ...session, order: updatedOrder };
      setSession(updatedSession);
      console.log(`updatedSession after updating field: "${field} : ${updatedSession}`);
    }
  };

  const handleBackButton = () => {
    // Navigate to the finalize quantities page
    navigateFunction('checkout_finalize_quants');
  }

  const handlePaymentSuccess = (paymentToken: TokenResult) => {
    // Validate recipient details first
    const missingFields = validateRecipientDetails();
    if (missingFields.length > 0) {
      alert(`Please enter the following recipient fields: ${missingFields.join(", ")}`);
      return; // Exit the function if validation fails
    }

    // Existing logic for successful payment
    console.log('paymentToken: ', paymentToken.token);
    console.log('session id: ', session?.id);
    let billingFirstName = paymentToken.details?.billing?.givenName || '';
    let billingLastName = paymentToken.details?.billing?.familyName || '';
    let billingName = billingFirstName + ' ' + billingLastName;
    if (billingFirstName && billingLastName) {
      updateRecipientDetails('billingName', billingFirstName + ' ' + billingLastName);
    }
    setForceUpdate(f => !f);
    completePayment({ variables: { sessionId: session?.id, billingName: billingName, paymentToken: paymentToken.token } }).then(response => {
      console.log('completePayment response:', response);
      let result = response.data.completeOrderPayment;
      if (result.success) {
        if (session?.order) {
          const updatedOrder = {
            ...session.order,
            state: OrderStatus.Completed,
          };
          setSession({ ...session, order: updatedOrder });
        }
        navigateFunction('order_summary');
      }
      else {
        alert('Payment failed:' + (result.failureReason || 'Unknown Failure reason'));
      }
    })
      .catch(err => {
        console.error("handlePaymentSuccess: Error updating order in session:", err);
      });
  };

  const handlePaymentSuccess_delme = (paymentToken: TokenResult) => {
    // Navigate to the summary page

    console.log('paymentToken: ', paymentToken.token);
    console.log('session id: ', session?.id);
    let billingFirstName = paymentToken.details?.billing?.givenName || '';
    let billingLastName = paymentToken.details?.billing?.familyName || '';
    let billingName = billingFirstName + ' ' + billingLastName;
    if (billingFirstName && billingLastName) {
      updateRecipientDetails('billingName', billingFirstName + ' ' + billingLastName);
    }
    setForceUpdate(f => !f);
    // updateRecipientDetails(paymentToken.billing as keyof Order, e.target.value)
    completePayment({ variables: { sessionId: session?.id, billingName: billingName, paymentToken: paymentToken.token } }).then(response => {
      console.log('completePayment response:', response);
      let result = response.data.completeOrderPayment;
      if (result.success) {
        if (session?.order) {
          const updatedOrder = {
            ...session.order,
            state: OrderStatus.Completed,
          };
          setSession({ ...session, order: updatedOrder });
        }
        navigateFunction('order_summary');
      }
      else {
        alert('Payment failed:' + (result.failureReason || 'Unknown Failure reason'));
      }
    })
      .catch(err => {
        console.error("handlePaymentSuccess: Error updating order in session:", err);
      });
  };
  const handleSameAsBillingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setIsSameAsBilling(isChecked);
    if (session?.order) {
      // const { billingName, billingPhoneNumber, billingEmailAddress } = session.order;
      const updatedOrder = {
        ...session.order,
        recipientIsPayer: isChecked
      };
      setSession({ ...session, order: updatedOrder });
    }
  };
  const validateRecipientDetails = () => {
    const missingFields = [];
    const { recipientName, recipientPhoneNumber, recipientEmailAddress } = session?.order || {};

    if (!recipientName) missingFields.push("Full Name");
    if (!recipientPhoneNumber) missingFields.push("Phone Number");
    if (!recipientEmailAddress) missingFields.push("Email Address");

    return missingFields;
  };

  // Function to display recipient info with an edit button
  const googlePayPaymentRequestFactory = () => {
    console.log('googlePayPaymentRequestFactory');
    let lineItems = session?.order?.menuItems ? session.order.menuItems.map(item => {
      return {
        label: item.name,
        amount: item.price.toString(),
        pending: true,
        imageUrl: "",
        productUrl: "",
        id: item.squareItemId
      }
    }) : [];

    let paymentRequest = {
      countryCode: "US",
      currencyCode: "USD",
      requestBillingContact: true,
      requestShippingContact: false,

      total: {
        amount: session?.order?.total.toFixed(2) || '0.00',
        label: "Total",
      }
    }
    console.log('session.order :', session?.order);
    console.log('paymentRequest: ', paymentRequest);
    return paymentRequest;
  }
  const RecipientInfoDisplay = () => {
    const { recipientName, recipientPhoneNumber, recipientEmailAddress } = session?.order || {};
    if (recipientName || recipientPhoneNumber || recipientEmailAddress) {
      return (
        // Have the label and the receipient info in two separate flex columns
        <div className='grid grid-cols-2 gap-4"'>
          <div className='font-bold'>
            <div>Full Name:</div>
            <div>Phone Number:</div>
            <div>Email Address:</div>
          </div>
          <div>
            <div>{recipientName}</div>
            <div>{recipientPhoneNumber}</div>
            <div>{recipientEmailAddress}</div>
            <button onClick={openDialog}>Edit</button>
          </div>
        </div>

      );
    } else {
      return (
        <button onClick={openDialog}>Enter Recipient Info</button>
      );
    }
  };
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  type recipientFieldName = 'recipientName' | 'recipientPhoneNumber' | 'recipientEmailAddress';
  type billingFieldName = 'billingName' | 'billingPhoneNumber' | 'billingEmailAddress';
  const inputFieldsBilling: [string, billingFieldName][] = [
    ['Full Name', 'billingName'],
    ['Phone Number', 'billingPhoneNumber'],
    ['Email Address', 'billingEmailAddress'],
  ];

  const inputFieldsRecipient: [string, recipientFieldName][] = [
    ['Full Name (required)', 'recipientName'],
    ['Phone Number (required)', 'recipientPhoneNumber'],
    ['Email Address (required)', 'recipientEmailAddress'],
  ];
  return (

    <div style={{ display: 'grid', padding: '0.5rem', gridTemplateColumns: '1fr 1fr 1fr', gap: '20px' }}>
      {/* Column 2: Order Summary */}
      <div>
        <h3>Order Summary</h3>
        <hr />
        <div><OrderItems /></div>
        <div className='flex justify-between'>
          <div>Order Subtotal</div>
          <div className="">${subTotal.toFixed(2)}</div>
        </div>
        <div className='flex justify-between'>
          <div>Sales Tax</div>
          <div className="">${tax.toFixed(2)}</div>
        </div>
        {/* <div className='flex justify-between'>
          <div>
            <div>
              Tip
            <button onClick={() => setTipPercentage(0.10)}>10%</button>
            <button onClick={() => setTipPercentage(0.15)}>15%</button>
            <button onClick={() => setTipPercentage(0.20)}>20%</button>       
            <button onClick={() => setTipPercentage(0)}>No Tip</button>            
            </div>
          </div>
          <div className="">${tip.toFixed(2)}</div>
        </div> */}
        
        <div className="flex justify-between items-center p-2">
          <span className="font-semibold">Tip:</span>
          <TipSelector setTipPercentage={setTipPercentage} />
          <span className="font-semibold">${tip.toFixed(2)}</span>
        </div>

        <hr />
        <div>
          Order Total <span style={{ float: 'right' }}>${(subTotal + tax + tip).toFixed(2)}</span>
        </div>

      </div>

      {/* Column 3: Payment Method */}
      <div>
        <h3>Payment</h3>
        <hr />

        <div className="container">
          <div className="text-center">EXPRESS CHECKOUT</div>
          <PaymentForm
            applicationId={squareApplicationId || ''}
            cardTokenizeResponseReceived={(token, verifiedBuyer) => {
              console.log('token:', token);
              console.log('verifiedBuyer:', verifiedBuyer);
              handlePaymentSuccess(token);
            }}
            locationId={locationId}
            createPaymentRequest={() => googlePayPaymentRequestFactory()}
          >
            <div className='flex'>
              <GooglePay className='flex-1' />

            </div>
            <hr className="m-4" />
            <div className="text-center">CREDIT CARD CHECKOUT</div>
            <div className='font-bold'>Billing Info</div>
            {inputFieldsBilling.map(([label, fieldName]) => (
              <React.Fragment key={fieldName}>
                {/* <div className='mt-4 font-bold vert'>{label}*</div> */}
                <div className='mb-4 flex'>
                  <input
                    className="flex-1"
                    type="text"
                    placeholder={label}
                    value={session?.order?.[fieldName] || ''}
                    onChange={(e) => updateRecipientDetails(fieldName as keyof Order, e.target.value)}
                    required
                  />
                </div>
              </React.Fragment>
            ))}
            <CreditCard className="m-0 p-0" />
          </PaymentForm>

        </div>
      </div>
      {/* Column 1: Pickup Info */}
      {/* Button to open the modal */}
      <div>
        <h3>Person Picking Up</h3>
        {/* Checkbox for if same as billing contact */}
        {/* <div className='flex'>
          <div className='pr-2'>
            <input 
              type="checkbox" 
              id="sameAsBilling" 
              name="sameAsBilling" 
              checked={session?.order?.recipientIsPayer || false}
              onChange={handleSameAsBillingChange}
            />
          </div>
          <label htmlFor="sameAsBilling">Same as Billing Contact</label>
        </div> */}
        {inputFieldsRecipient.map(([label, fieldName]) => (
          <React.Fragment key={fieldName}>
            <div className='mb-4 flex'>
              <input
                className="flex-1"
                type="text"
                placeholder={label}
                value={session?.order?.[fieldName] || ''}
                onChange={(e) => updateRecipientDetails(fieldName as keyof Order, e.target.value)}
                disabled={isSameAsBilling}
                required
              />
              <div className='text-red-700'>*</div>
            </div>
          </React.Fragment>
        ))}
        <div className='text-red-700'>* Required</div>
      </div>

      {/* Modal for entering recipient info */}
      <CCDialog isOpen={isDialogOpen} setIsOpen={setIsDialogOpen} title={"Recipient Details"} actionButtonText='Save' icon={<IdentificationIcon />}>
        <div className='grid grid-cols-[auto_minmax(0,1fr)] gap-4 items-center'>
          {inputFieldsBilling.map(([label, fieldName]) => (
            <React.Fragment key={fieldName}>
              {/* <div className=' col-auto font-bold vert'>{label}:</div> */}
              <div className=' col-span-1'>
                <input
                  type="text"
                  placeholder={label}
                  value={session?.order?.[fieldName] || ''}
                  onChange={(e) => updateRecipientDetails(fieldName as keyof Order, e.target.value)}
                  required
                />
              </div>
            </React.Fragment>
          ))}
        </div>

      </CCDialog>
      {/* Footer */}
      <div style={{ gridColumn: '1 / span 3', marginTop: '20px' }}>
        <hr />
        <button onClick={handleBackButton}>Back</button>
      </div>
    </div>
  );
}

export default CheckoutPayment;
