import React, { useState, useEffect, ReactNode, createContext, useContext, useCallback, useRef } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { GET_SESSION } from '../../graphql/queries/queries';
import { CREATE_SESSION, UPDATE_ORDER_IN_SESSION } from '../../graphql/mutations/mutations'
import { Order, OrderStatus, Session } from '../../graphql/generated-types';
import { useLocation, useNavigate } from 'react-router-dom';

// This props will be passed on to child components
type SessionContextType = {
  session: Session | null;
  setSession: React.Dispatch<React.SetStateAction<Session | null>>;
  navigateWithSession: (path: string) => void;
};

const SessionContext = createContext<SessionContextType | null>(null);
type SessionProviderProps = {
  children: ReactNode;
};
// Utility function to remove __typename
function omitTypename(key: string, value: any) {
  return key === '__typename' || key === 'id' ? undefined : value;
}
let updateCount = 0;

const SessionProvider: React.FC<SessionProviderProps> = ({ children }) => {
  console.log("Creating SessionProvider");
  const [session, setSession] = useState<Session | null>(null);
  const [sessionId, setSessionId] = useState<string | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [updateOrder, { error }] = useMutation(UPDATE_ORDER_IN_SESSION);
  const [createNewSessionMutation] = useMutation(CREATE_SESSION);
  const creatingSessionRef = useRef(false);

  // Create a queue of promises to update the order
  const updateOrderQueue = useRef<Object[]>([]);
  // This function will take a path and add the sessionId to the query params
  const navigateWithSession = (path: string) => {
    let finalPath = path;
    if (sessionId) {
      const currentParams = new URLSearchParams(location.search);
      currentParams.set('sessionId', sessionId);
      finalPath = `${path}?${currentParams.toString()}`;
    } else {
      console.log("Session ID is null, navigating without session ID."); // Debugging line
    }
    navigate(finalPath);
  };

  // Mutation for creating a new session
  const createNewSession = useCallback(() => {
    // Here you would return the mutation call
    return createNewSessionMutation();
  }, [createNewSessionMutation]);

  const { data /*, loading */ } = useQuery(GET_SESSION, {
    variables: { sessionId: getSessionIdFromQueryParams() },
    skip: !getSessionIdFromQueryParams()
  });

  function getSessionIdFromQueryParams(): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    let sessionId = urlParams.get('sessionId');
    console.log('sessionId from Query Params:', sessionId);

    return sessionId;
  }
  // Function to update URL with sessionId
  const updateURLWithSessionId = (sessionId: string) => {
    const url = new URL(window.location.href);
    url.searchParams.set('sessionId', sessionId);
    window.history.pushState({}, '', url);
  };

  // Check if there's a sessionId in the URL, if there is, set the sessionId state
  // otherwise create a new session
  useEffect(() => {
    console.log('SessionProvider Mounted');
    const initialSessionId = getSessionIdFromQueryParams();
    if (initialSessionId && session?.order?.state !== OrderStatus.Completed) {
      console.log("Setting Initial session ID:", initialSessionId);
      setSessionId(initialSessionId);
    } else {
      // Create a new session if there's no sessionId in the URL
      if (creatingSessionRef.current) return;
      creatingSessionRef.current = true;
      createNewSession().then(response => {
        console.log('createNewSession response:', response);
        const newSessionId = response.data.createSession.id;
        updateURLWithSessionId(newSessionId);
        setSession(response.data.createSession);
        setSessionId(newSessionId);
        creatingSessionRef.current = false;

      }).catch(err => {
        creatingSessionRef.current = false;
        console.error("Error creating new session:", err);
      });
    }
    return () => console.log('SessionProvider Unmounted');
  }, []);

  useEffect(() => {
    if (sessionId && data && data.getSession) {
      // Update the session state with fetched data
      console.log('data.getSession:', data.getSession);
      setSession(data.getSession);
    }
  }, [data, sessionId]);
  // Utility function for deep comparison
  function isOrderChanged(oldOrder: Order, newOrder: Order): boolean {
    // Implement the logic to compare relevant parts of the order
    // Return true if they are different, false otherwise
    return oldOrder.subtotal !== newOrder.subtotal || oldOrder.total !== newOrder.total;
  }
  useEffect(() => {
    if (session && session.order) {
      console.log("Updating order in session:", session.order);

      // Add up subtotal of menuItems before taxes and tip
      let subtotal = 0;
      if (session.order.menuItems) {
        session.order.menuItems.forEach(item => {
          subtotal += item.price * item.quantity;
        });
      }
      // Create a new order object with the updated subtotal
      const updatedOrder = {
        ...session.order,
        subtotal: subtotal,
        total: subtotal + session.order.tax + session.order.tip
      };

      const sanitizedOrder = JSON.parse(JSON.stringify(updatedOrder, omitTypename))
      console.log("Sanitized order:", sanitizedOrder);
      updateCount++;
      // console.log("ADDING to updateOrderQueue");
      updateOrderQueue.current.push({ variables: { sessionId: session.id, order: sanitizedOrder } });
      // console.log(">>>>>> updateOrderQueue: current", updateOrderQueue.current.length);
      if (updateOrderQueue.current.length === 1) {
        const updateOrderRegime = (di:any) => updateOrder(di)
          .then(response => {
            console.log("Top of updateOrderRegime", updateOrderQueue.current.length);
            updateOrderQueue.current.shift();

            // Update local session state with the new order details
            console.log("!!!!! updateOrderInSession response:", response);
            if (isOrderChanged(session.order as Order, sanitizedOrder)) {
              setSession({
                ...session,
                order: updatedOrder
              });
            }
            if (updateOrderQueue.current.length > 0) {
              let dequeueItem = updateOrderQueue.current.shift();
              updateOrderRegime(dequeueItem);
            }
          })
          .catch(err => {
            updateCount--;
            console.error("Error updating order in session:", err);
          });
        updateOrderRegime(updateOrderQueue.current[0]);
      }

    }
  }, [session, updateOrder]);

  useEffect(() => {
    console.log("PreCartMenuItems changed:", session?.order?.preCartMenuItems);

  }, [session?.order?.preCartMenuItems]);

  useEffect(() => {
    if (error) {
      console.error("Mutation error:", error);
      updateCount--;
    }
  }, [error]);

  return (
    <SessionContext.Provider value={{ session, setSession, navigateWithSession }}>
      {children}
    </SessionContext.Provider>
  );
};

export { SessionContext, SessionProvider };

export const useSession = () => {
  const context = useContext(SessionContext);
  if (!context) {
    throw new Error("useSession must be used within a SessionProvider");
  }
  return context;
};