// component/OrderDetails.tsx
import { useSession } from './SessionContext'; // Adjust the path as necessary
import React, { useState, useEffect, useRef } from 'react';
import { useGetSquareCatalogQuery, SquareItem, Order, MenuItem } from '../../graphql/generated-types';  // Adjust the path
import OverlayPane from './OrderDetailPopup';
import { CateringHelperShellProps } from './CateringHelperShell';
import { DietaryPreference, OrderRecommender } from 'src/business_logic/OrderRecommender';
import SwapOrderDetailsDialog from './SwapOrderDetailsDialog';
import { CCMenuItem } from 'src/business_logic/CCMenuItem';
import { MenuItemPlusDietary } from './MenuItemPlusDietary';
function OrderDetails({ navigateFunction }: CateringHelperShellProps) {
  const { session, setSession } = useSession();
  const { loading, error, data } = useGetSquareCatalogQuery();

  const [selectedItem, setSelectedItem] = useState<MenuItem | null>(null);
  const [orderRecommender, setOrderRecommender] = useState<OrderRecommender | null>(null);
  const [swapOutMenuItem, setSwapItem] = useState<MenuItem | null>(null);
  const [itemQuantities, setItemQuantities] = useState<{ [key: string]: number }>({});
  const [groupedItems, setGroupedItems] = useState<{ [category: string]: MenuItem[] }>({});
  // let orderRecommender = new OrderRecommender(1, ServingStyle.Buffet, squareCatalogItems, preCartMenuItems);

  // const [firstRunAfterMount, setFirstRun] = useState(true);
  /**
   * useEffect Hook to update item quantities. There are two cases to consider:
   * 
   * 1) The first case is when we are initially mounting the component and we have a session and data. This
   * will populate the inputFields with what ever we have saved off in session.order.preCartmenuItems
   * 
   * 2) The second case is when the user changes the headcount. This will wipe out any tweaks to menu item
   * quantities that the user has made. We will then re-calculate the quantities based on the new headcount.
   */
  const firstRunAfterMount = useRef(true);
  useEffect(() => {
    if (session?.order && data) {
      let newQuantities: { [key: string]: number } = {};
      let preCartMenuItems: MenuItem[] = [];
      let squareCatalogItems = (data?.getSquareCatalog || []) as SquareItem[];
      const orderRecommender = new OrderRecommender(session.order.headcount, session.order.servingStyle, squareCatalogItems, preCartMenuItems);
      setOrderRecommender(orderRecommender);
      // If this is the first run, then we want to populate the input fields with the quantities
      if (firstRunAfterMount.current && session.order.preCartMenuItems.length) {
        session.order.preCartMenuItems.forEach((preCartMenuItem) => {
          newQuantities[preCartMenuItem.squareItemId] = preCartMenuItem.quantity;
        });
      }

      // Otherwise, we want to re-calculate the quantities based on the new headcount
      else {

        let recommendedItems = orderRecommender.calculateRecommendedQuantities(DietaryPreference.Meat, session.order.headcount);

        console.log("recommendedItems: ", JSON.stringify(recommendedItems, null, 2));
        preCartMenuItems = recommendedItems || [];
        recommendedItems?.forEach((item, index) => {
          if (!item) return;
          newQuantities[item.squareItemId] = item.quantity;
          // preCartMenuItems.push(item);
        });
      }

      // Update the state with the calculated quantities
      setItemQuantities(newQuantities);

      // Update the session with the preCartMenuItems
      if (preCartMenuItems.length > 0) {
        console.log("PrecartMenuItems change setting session.")
        setSession({
          ...session,
          order: {
            ...session.order,
            preCartMenuItems: preCartMenuItems
          } as Order
        });
      }

      firstRunAfterMount.current = false;

      // Group items by category
      const groupedItems: { [category: string]: any[] } = {};
      let itemsToGroup = preCartMenuItems.length > 0 ? preCartMenuItems : session.order.preCartMenuItems;
      itemsToGroup.forEach(item => {
        if (item && item.categories.length > 1) {
          let category = item.categories[1];
          if (!groupedItems[category]) {
            groupedItems[category] = [];
          }
          groupedItems[category].push(item);
        }
      });
      setGroupedItems(groupedItems);
      console.log("groupedItems: ", JSON.stringify(groupedItems, null, 2));
    }
  }, [session?.order?.headcount, data]);
  const renderCategorySection = (category: string, items: MenuItem[]) => (
    <>
      <h3 className='text-center'>{category}</h3>
      <table className='w-full text-sm'>
        <thead>
          <tr>
            <th className='w-4'>Quantity</th>
            <th className='text-center'>Item</th>
            <th></th>
            <th className='w-4 pt-1'>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          {items.map(item => (
            <tr key={item.squareItemId} className='border-b border-gray-400 text-sm' >
              <td className='py-1 pl-2 pr-2'>
                <input
                  type="number"
                  value={itemQuantities[item.squareItemId] || item.quantity || 0}
                  min="0"
                  max="99"
                  className="w-12"
                  onChange={(e) => handleInputChange(item.squareItemId, e)}
                />
              </td>
              <td className="hover:text-pink-700 hover:underline" onClick={() => setSelectedItem(item)}><MenuItemPlusDietary menuItem={item} /></td>
              <td>
                <div className='flex justify-end pr-12'>
                  <button onClick={() => handleRemoveItem(item.squareItemId)}>X</button>
                  <button onClick={() => handleSwapOrdedDetailsItem(item, 1)}>swap</button>
                </div>
              </td>
              <td className='text-right'>${((getPreCartItemQuantity(item.squareItemId) * item.price)).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
  const handleInputChange = (itemId: string, event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Handling input change on order details");
    const newQty = parseInt(event.target.value);

    // Update the local state with the new quantity for the specific item
    setItemQuantities(prevQuantities => ({
      ...prevQuantities,
      [itemId]: newQty
    }));
    // Update the session's preCartMenuItems with the new quantity
    if (session?.order) {
      const updatedPreCartMenuItems = session.order.preCartMenuItems.map(item => {
        if (item.squareItemId === itemId) {
          return {
            ...item,
            quantity: newQty
          };
        }
        return item;
      });

      setSession({
        ...session,
        order: {
          ...session.order,
          preCartMenuItems: updatedPreCartMenuItems 
        } as Order
      });
    }
  }
  const handleRemoveItem = (itemId: string) => {
    console.log("Removing item from order details");

    // Update the local state to remove the item quantity
    setItemQuantities(prevQuantities => {
      const updatedQuantities = { ...prevQuantities };
      delete updatedQuantities[itemId];
      return updatedQuantities;
    });

    // Update the session's preCartMenuItems to remove the item
    if (session?.order) {
      const updatedPreCartMenuItems = session.order.preCartMenuItems.filter(item => item.squareItemId !== itemId);

      setSession({
        ...session,
        order: {
          ...session.order,
          preCartMenuItems: updatedPreCartMenuItems
        } as Order
      });

      // Regroup the items by category after removal
      const updatedGroupedItems = groupItemsByCategory(updatedPreCartMenuItems);
      setGroupedItems(updatedGroupedItems);
    }
  }

  const groupItemsByCategory = (items: MenuItem[]) => {
    const grouped: { [category: string]: MenuItem[] } = {};
    items.forEach(item => {
      if (item && item.categories.length > 1) {
        let category = item.categories[1]; // Assuming the relevant category is at index 1
        if (!grouped[category]) {
          grouped[category] = [];
        }
        grouped[category].push(item);
      }
    });
    return grouped;
  }

  const handleAddToCart = () => {
    // The purpose of this code is to update the session context with the menu items that the user has selected
    const updatedMenuItems: Array<MenuItem> = new Array<MenuItem>();
    const orderMenuItems = session?.order?.menuItems || [];
    orderMenuItems.length && orderMenuItems.forEach(val => updatedMenuItems.push(Object.assign({}, val)));
    session?.order?.preCartMenuItems.forEach(item => {
      //find the preCartMenuItem in the updatedMenuItems
      let updatedMenuItem = updatedMenuItems?.find((updatedItem) => updatedItem.squareItemId === item.squareItemId);
      if (updatedMenuItem) {
        updatedMenuItem.quantity += item.quantity;
      }
      else if (item.quantity > 0) {
        updatedMenuItems.push(item);
      }
    });

    if (session) {
      setSession({
        ...session,
        order: {
          ...session.order,
          menuItems: updatedMenuItems as [MenuItem]
        } as Order
      });
    }
    console.log('updatedMenuItems :', updatedMenuItems);
    console.log('session.order.menuItems:', session?.order?.menuItems);


    navigateFunction('menu'); // Navigate to Menu page
  };
  function handleSwapOrdedDetailsItem(item: MenuItem, position: number): void {
    setSwapItem(item);
  }
  function swapMenuItem(swapInMenuItem: MenuItem, swapOutMenuItem: MenuItem): void {
    console.log("Swapping out menu item");
    let updatedPreCartMenuItems: MenuItem[] = [];
    if (session?.order?.preCartMenuItems) {
      updatedPreCartMenuItems = session.order.preCartMenuItems.map(item => {
        if (item.squareItemId === swapOutMenuItem.squareItemId) {
          swapInMenuItem.quantity = item.quantity;
          setItemQuantities(prevQuantities => ({
            ...prevQuantities,
            [swapInMenuItem.squareItemId]: item.quantity
          }));
          return CCMenuItem.toMenuItem(swapInMenuItem);
        }
        return CCMenuItem.toMenuItem(item);
      });

      setSession({
        ...session,
        order: {
          ...session.order,
          preCartMenuItems: updatedPreCartMenuItems
        } as Order
      });

      // Regroup the items by category after swap
      const updatedGroupedItems = groupItemsByCategory(updatedPreCartMenuItems);
      setGroupedItems(updatedGroupedItems);
    }

  }
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  function getPreCartItemQuantity(itemId: string) {
    if (session?.order?.preCartMenuItems) {
      let preCartItem = session.order.preCartMenuItems.find((item) => item.squareItemId === itemId);
      if (preCartItem) {
        return preCartItem.quantity;
      }
    }
    return 0;
  }

  return (
    <div className='relative'>
       <div >
        <button className="absolute right-0" onClick={handleAddToCart}>
          Add to Cart
        </button>
      </div>
      {Object.entries(groupedItems).map(([category, items]) => renderCategorySection(category, items))}
      {selectedItem && <OverlayPane item={selectedItem} onClose={() => setSelectedItem(null)} />}
      {swapOutMenuItem && <SwapOrderDetailsDialog menuItemList={orderRecommender?.allCateringMenuItems} nameOfMenuItemToSwapOut={swapOutMenuItem.name} onSelectItemToSwapIn={(swapInMenuItem) => swapMenuItem(swapInMenuItem, swapOutMenuItem)} onClose={() => setSwapItem(null)} />}
    </div>
  );
}

export default OrderDetails;


