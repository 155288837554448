import React, { useState } from 'react';

interface TipSelectorProps {
  setTipPercentage: (percentage: number) => void; // Callback function to update parent state
}

const TipSelector: React.FC<TipSelectorProps> = ({ setTipPercentage }) => {
  const [selectedTip, setSelectedTip] = useState<number>(0.15); // Default tip

  const handleTipChange = (percentage: number) => {
    setSelectedTip(percentage);
    setTipPercentage(percentage);
  };

  return (
    <div className="flex space-x-2">
      <button
        className={`${selectedTip === 0.10 ? 'bg-selected' :''}`}
        onClick={() => handleTipChange(0.10)}
      >
        10%
      </button>
      <button
        className={` ${selectedTip === 0.15 ?  'bg-selected' :''}`}
        onClick={() => handleTipChange(0.15)}
      >
        15%
      </button>
      <button
        className={`${selectedTip === 0.20 ?  'bg-selected' :''}`}
        onClick={() => handleTipChange(0.20)}
      >
        20%
      </button>
      <button
        className={`${selectedTip === 0 ?  'bg-selected' :''}`}
        onClick={() => handleTipChange(0)}
      >
        No Tip
      </button>
    </div>
  );
};

export default TipSelector;
