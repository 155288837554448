import React from 'react';

function OrderModification() {
  return (
    <div>
      <h2 className="cateringHelperSection">  Modify Your Order</h2>
      {/* Add your order modification logic here */}
    </div>
  );
}

export default OrderModification;
