export class MathUtils {
  /**
   * Clamps a value between a min and max value, using the min value if the value is less than the min, 
   * and the max value if the value is greater than the max.
   * @param value The number to clamp
   * @param min The minimum allowed value
   * @param max The maximum allowed value
   * @returns clamped value
   */
  public static clamp(value: number, min: number, max: number): number {
    // (500, 1, 400) = math.min(math.max(500,1), 400) 
    //               = math.min(500,400) = 400

    // (0, 1, 400)   = math.min(math.max(0,1), 400) 
    //               = math.min(1,400) = 1

    // (10, 1, 400)   = math.min(math.max(10,1), 400) 
    //               = math.min(10,400) = 10

    return Math.min(Math.max(value, min), max);
  }
}