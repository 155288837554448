// component/Cart.tsx
import React, { useState } from 'react';
import { useCart } from './CartContext'; // Adjust the path
import { MenuItem, Session } from 'src/graphql/generated-types';
import { useSession } from './SessionContext'; // Adjust the path as necessary
import { CateringHelperShellProps } from './CateringHelperShell';
function Cart({ navigateFunction }: CateringHelperShellProps) {
  const { session, setSession } = useSession();
  const { cartItems, updateCartItem } = useCart();

  // State for date and time dropdowns
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedTime, setSelectedTime] = useState<string>('');

  // Generate the next 30 dates excluding weekends
  const getNextDates = () => {
    const dates: string[] = [];
    let currentDate = new Date();

    // Check if the current time is after 2pm
    if (currentDate.getHours() >= 14) {
      // If it's after 2pm, set the starting date to the day after tomorrow
      currentDate.setDate(currentDate.getDate() + 2);
    } else {
      // Otherwise, set it to be tomorrow
      currentDate.setDate(currentDate.getDate() + 2);
    }

    while (dates.length < 30) {
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) { // Exclude Sundays (0) and Saturdays (6)
        dates.push(currentDate.toLocaleDateString('en-US'));
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  };

  // Calculate total cost
  const totalCost = cartItems.reduce((sum, item) => sum + (item.price) * item.quantity, 0);

  const handleQuantityChange = (item: MenuItem, event: React.ChangeEvent<HTMLInputElement>) => {
    const newQty = parseInt(event.target.value);
    updateCartItem(item.squareItemId, newQty);
  };

  // Clear the shopping cart by clearing the menu items in session.order.menuItems
  function handleClearCart(): void {
    setSession(prevSession => {
      if (!prevSession) return null;
      return {
        ...prevSession,
        order: {
          ...prevSession.order,
          menuItems: []
        }
      } as Session;
    })
  }

  function handleCheckout(): void {
    // convert selectedDate and selectedTime to a DateTime in iso format.
    // Access the select elements directly
    const dateSelect = document.getElementById('dateSelect') as HTMLSelectElement;
    const timeSelect = document.getElementById('timeSelect') as HTMLSelectElement;

    // Get the selected values
    const selectedDate = dateSelect ? dateSelect.value : '';
    const selectedTime = timeSelect ? timeSelect.value : '';
    console.log("date and time:", selectedDate, selectedTime);

    // Ensure the date is in a format that can be parsed (e.g., "MM/DD/YYYY")
    const formattedDate = selectedDate.replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$1/$2/$3');
    console.log('formattedDate:', formattedDate);
    // Convert the 12-hour time format to 24-hour format for proper parsing
    const timeSegment = selectedTime.match(/^(\d{1,2}):(\d{2})([ap])/);
    console.log('timeSegment:', timeSegment);
    if (!timeSegment) {
      console.log("date and time:", selectedDate, selectedTime);
      throw new Error('Invalid time format');
    }

    let hours = parseInt(timeSegment[1], 10);
    const minutes = timeSegment[2];
    const ampm = timeSegment[3];

    if (ampm === 'p' && hours < 12) hours += 12;
    if (ampm === 'a' && hours === 12) hours = 0;

    const formattedTime = `${hours}:${minutes}`;

    // Combine the formatted date and time
    let formattedDateTime = `${formattedDate} ${formattedTime}`;
    console.log('formattedDateTime:', formattedDateTime);
    const selectedDateTime = new Date(formattedDateTime);
    console.log('selectedDateTime:', selectedDateTime);

    // Update the session with the selected date and time
    setSession(prevSession => {
      if (!prevSession) return null;
      return {
        ...prevSession,
        order: {
          ...prevSession.order,
          pickupOrDeliveryDateTime: selectedDateTime.toISOString()
        }
      } as Session;
    });

    navigateFunction('checkout_finalize_quants');
  }

  return (

    <div className="bg-[#FFDFAA] fixed text-base right-0 h-screen w-52 p-4 overflow-y-auto">
      <h3>Shopping Cart</h3>
      <h4>Pickup date</h4>
      <select id="dateSelect" className='m-1 p-1' value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)}>
        {getNextDates().map(date => (
          <option key={date} value={date}>{date}</option>
        ))}
      </select>
      <h4>Pickup time</h4>
      <select id="timeSelect" className='m-1 p-1' value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)}>
        {['10:00a', '10:15a', '10:30a', '10:45a', '11:00a', '11:15a', '11:30a', '11:45a', '12:00p','12:15p','12:30p','12:45p','1:00p'].map(time => (
          <option key={time} value={time}>{time}</option>
        ))}
      </select>

      <button style={{ width: '100%', marginTop: '1rem' }} onClick={handleCheckout}>Checkout</button>



      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '1rem' }}>
        <span>Items: {cartItems.reduce((total, item) => total + item.quantity, 0)}</span>

        <span>${totalCost.toFixed(2)}</span>
      </div>

      {session?.order?.menuItems?.map(item => (
        <div key={item.squareItemId} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '1rem' }}>
          <span>{item.name}</span>
          <input type="number" value={item.quantity} onChange={(e) => handleQuantityChange(item, e)} style={{ width: '50px' }} />
        </div>
      ))}
      <button style={{ width: '100%', marginTop: '1rem' }} onClick={handleClearCart}>Clear Cart</button>
    </div>
  );
}

export default Cart;
