import { CheckIcon } from '@heroicons/react/24/solid'


export enum CheckoutStepStatus {
    Complete = 'complete',
    Current = 'current',
    Upcoming = 'upcoming',
    Disabled = 'disabled'
}

export type CheckoutStep = {
    id: number;
    name: string;
    navigationLocation: string;
    status: CheckoutStepStatus;
}
export interface CheckoutStepsProps {
    steps: Array<CheckoutStep>;
    navigateFunction: (path: string) => void;
}
export default function CheckoutSteps({ steps, navigateFunction }: CheckoutStepsProps) {
  const graphicColor = 'bg-secondary';
  const borderColor = 'border-secondary';
  const textColor = 'text-secondary';
  const completeMouseOver = 'bg-secondary_dark';
  return (
    <nav className='bg-white'  aria-label="Progress">
      <ol role="list" className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
        {steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex md:flex-1">
            {step.status === CheckoutStepStatus.Complete ? (
              <a onClick={() => navigateFunction(step.navigationLocation)}  className="group flex w-full items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  {/* <span className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full ${graphicColor} group-hover:bg-secondary_dark`}> */}
                  <span className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full ${graphicColor} group-hover:${completeMouseOver}`}>
                    <CheckIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </span>
                  <span className="ml-4 text-sm font-medium text-gray-900">{step.name}</span>
                </span>
              </a>
            ) : step.status === CheckoutStepStatus.Current ? (
              <a onClick={() => navigateFunction(step.navigationLocation)}  className="flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
                <span className={`flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 ${borderColor}`}>
                  <span className={`${textColor}`}>{step.id}</span>
                </span>
                <span className={`ml-4 text-sm font-medium ${textColor}`}>{step.name}</span>
              </a>
              
            ) : step.status === CheckoutStepStatus.Disabled ? (
              <a  className="flex items-center px-6 py-4 text-sm font-medium text-gray-400 pointer-events-none no-underline" aria-current="step">
                <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-gray-300 group-hover:border-gray-400">
                  <span className="text-gray-400">{step.id}</span>
                </span>
                <span className="ml-4 text-sm fonst-medium  text-gray-400">{step.name}</span>
              </a>
            ) : (
              <a onClick={() => navigateFunction(step.navigationLocation)}  className="group flex items-center">
                <span className="flex items-center px-6 py-4 text-sm font-medium">
                  <span className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2 border-black group-hover:border-gray-400">
                    <span className="text-gray-500 group-hover:text-gray-900">{step.id}</span>
                  </span>
                  <span className="ml-4 text-sm font-medium text-black group-hover:text-gray-900">{step.name}</span>
                </span>
              </a>
            )}

            {stepIdx !== steps.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  )
}
