// queries.ts
import { gql } from 'graphql-tag';

export const GET_SESSION = gql`
  query GetSession($sessionId: ID!) {
    getSession(id: $sessionId) {
      id
      order {
        id
        headcount
        mealType
        servingStyle
        dietaryPreferences {
          vegetarianMeals
          veganMeals
          glutenFreeMeals
        }
        menuItems {
          squareItemId
          quantity
          name
          price
          description
          imageUrl
          category
          categories
          dietary
        }
        preCartMenuItems {
          squareItemId
          quantity
          name
          price
          description
          imageUrl
          category
          categories
        }
        fulfillmentMethod
        deliveryInfo {
          street
          city
          province
          postalCode
          date
          time
          instructions
          deliveryCharge
        }
        subtotal
        tax
        tip
        total
        recipientName
        recipientPhoneNumber
        recipientEmailAddress
        billingName
        billingPhoneNumber
        billingEmailAddress
        paymentMethod
        orderDateTime
        pickupOrDeliveryDateTime
        companyOrContactPerson
        memo
        idempotencyKey
        squareOrderId
        state
        recipientIsPayer
      }
      createdAt
    }
  }
`;
