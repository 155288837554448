import styled from 'styled-components';

export const ImageContainer = styled.div`
  position: relative;
  display: inline-block;
  margin: 10px;
`;

export const StyledButton = styled.button`
  position: relative;
  border-radius: 10%;
  width: 8rem;
  height: 8rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`;

export const ButtonText = styled.span`
  font-family: 'Pacifico', cursive;
  position: absolute;
  width: 100%;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: Black; // You can change this color to match your image
  font-size: 2rem; // adjust as per your needs
  text-shadow: 0 0 3px #ffffff, 0 0 5px #ffffff, 0 0 10px #ffffff, 0 0 15px #ffffff, 0 0 20px #ffffff, 0 0 20px #ffffff, 
  0 0 25px #ffffff, 0 0 30px #ffffff;
`;