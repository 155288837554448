import { SquareItem, MenuItem } from '../graphql/generated-types';
export class CCMenuItem implements MenuItem {
  name: string;
  price: number;
  quantity: number;
  category: string;
  squareItemId: string;
  description: string;
  imageUrl: string;
  popular: boolean;
  servings: number;
  categories: string[];
  dietary: string[];
constructor(squareMenuItem: SquareItem) {
    this.name = squareMenuItem.name;
    this.price = squareMenuItem.price || 0;
    this.quantity = 0;
    
    this.squareItemId = squareMenuItem.itemId;
    this.category = squareMenuItem.category || '';
    this.popular = squareMenuItem.popular || false;
    this.servings = squareMenuItem.servings || 1;
    this.imageUrl = squareMenuItem.imageUrl || '';
    this.description = squareMenuItem.description || '';
    this.categories = squareMenuItem.categories || [];
    this.dietary = squareMenuItem.dietary || [];
  }
  // public static fromMenuItem(menuItem : MenuItem): CCMenuItem {
  //   return new CCMenuItem({
  //     name: menuItem.name,
  //     price: menuItem.price,
  //     quantity: 0,
  //     category: menuItem.category,
  //     squareItemId: menuItem.squareItemId,
  //     description: menuItem.description,
  //     imageUrl: menuItem.imageUrl,
  //     popular: false,
  //     servings: 1,
  //     categories: menuItem.categories,
  //     dietary: menuItem.dietary
  //   });
  // }
  public static toMenuItem(menuItem : MenuItem): MenuItem {
    return {
      name: menuItem.name,
      price: menuItem.price,
      quantity: menuItem.quantity,
      category: menuItem.category,
      squareItemId: menuItem.squareItemId,
      description: menuItem.description,
      imageUrl: menuItem.imageUrl,
      categories: menuItem.categories,
      dietary: menuItem.dietary
    };
  }
}

