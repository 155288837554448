// components/MenuPage.tsx

import MenuCategories from './MenuCategories';
import { CateringHelperShellProps } from './CateringHelperShell'
import Cart from './Cart';

function MenuPage({navigateFunction} : CateringHelperShellProps)  {
  return (
    <div className="flex">
      <div className="flex-1">
        <MenuCategories />
      </div>
      <div className="w-56 ml-10"  >
        <Cart navigateFunction={navigateFunction}/>
      </div>
    </div>
  );
}

export default MenuPage;
