import React, { createContext, useContext, useEffect } from 'react';
import { useSession } from './SessionContext';
import { MenuItem } from 'src/graphql/generated-types';

interface CartContextProps {
  cartItems: MenuItem[];
  addToCart: (item: MenuItem) => void;
  updateCartItem: (itemId: string, newQty: number) => void;
  removeCartItem: (itemId: string) => void;
}

const CartContext = createContext<CartContextProps | undefined>(undefined);

export const CartProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const { session, setSession } = useSession();

  const removeCartItem = (itemId: string) => {
    // session
    if (session?.order) {
      let newMenuItems = session?.order?.menuItems?.filter((item) => item.squareItemId !== itemId);
      setSession({
        ...session,
        order: {
          ...session.order,
          menuItems: newMenuItems
        }
      });
    }
  };

  const addToCart = (item: MenuItem) => {
    // setCartItems(prevItems => {
      // Check if the item already exists in the cart
      if (session?.order) {
      let prevItems = session?.order?.menuItems || [];
      const existingItem = prevItems.find(i => i.squareItemId === item.squareItemId);
      if (existingItem) {
        // If it exists, update its quantity
        return prevItems.map(i =>
          i.squareItemId === item.squareItemId ? { ...i, qty: i.quantity + item.quantity } : i
        );
      } else {
        // Otherwise, add it as a new item
        prevItems = [...prevItems, item];
      }

      setSession({
        ...session,
        order: {
          ...session.order,
          menuItems: prevItems
          }
      });
    }
  };

  const updateCartItem = (itemId: string, newQty: number) => {
    if (session?.order) {
      let newMenuItems = session?.order?.menuItems?.map((item) => {
        if (item.squareItemId === itemId) {
          item.quantity = newQty;
        }
        return item;
      });
      setSession({
        ...session,
        order: {
          ...session.order,
          menuItems: newMenuItems
        }
      });
    }    
  };

  const cartContextValue: CartContextProps = {
    cartItems: session?.order?.menuItems || [],
    addToCart,
    updateCartItem,
    removeCartItem
  };

  return (
    <CartContext.Provider value={cartContextValue}>
      {children}
    </CartContext.Provider>
  );

};

export const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};
