import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
};

export type CompleteOrderResult = {
  __typename?: 'CompleteOrderResult';
  failureReason?: Maybe<Scalars['String']['output']>;
  success: Scalars['Boolean']['output'];
};

export type Customer = {
  __typename?: 'Customer';
  emailAddress?: Maybe<Scalars['String']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  nickname?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export type DeliveryInfo = {
  __typename?: 'DeliveryInfo';
  city?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  deliveryCharge?: Maybe<Scalars['Float']['output']>;
  instructions?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  province?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['String']['output']>;
};

export type DeliveryInfoInput = {
  city?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['String']['input']>;
  deliveryCharge?: InputMaybe<Scalars['Float']['input']>;
  instructions?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  province?: InputMaybe<Scalars['String']['input']>;
  street?: InputMaybe<Scalars['String']['input']>;
  time?: InputMaybe<Scalars['String']['input']>;
};

export type DietaryPreferences = {
  __typename?: 'DietaryPreferences';
  glutenFreeMeals: Scalars['Int']['output'];
  veganMeals: Scalars['Int']['output'];
  vegetarianMeals: Scalars['Int']['output'];
};

export type DietaryPreferencesInput = {
  glutenFreeMeals?: InputMaybe<Scalars['Int']['input']>;
  veganMeals?: InputMaybe<Scalars['Int']['input']>;
  vegetarianMeals?: InputMaybe<Scalars['Int']['input']>;
};

export enum FulfillmentMethod {
  Delivery = 'DELIVERY',
  Pickup = 'PICKUP'
}

export enum MealType {
  Dinner = 'Dinner',
  Lunch = 'Lunch',
  Unset = 'Unset'
}

export type MenuItem = {
  __typename?: 'MenuItem';
  categories: Array<Scalars['String']['output']>;
  category: Scalars['String']['output'];
  description: Scalars['String']['output'];
  dietary?: Maybe<Array<Scalars['String']['output']>>;
  imageUrl: Scalars['String']['output'];
  name: Scalars['String']['output'];
  price: Scalars['Float']['output'];
  quantity: Scalars['Int']['output'];
  squareItemId: Scalars['String']['output'];
};

export type MenuItemInput = {
  categories: Array<Scalars['String']['input']>;
  category: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  dietary?: InputMaybe<Array<Scalars['String']['input']>>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
  quantity?: InputMaybe<Scalars['Int']['input']>;
  squareItemId?: InputMaybe<Scalars['String']['input']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  completeOrderPayment?: Maybe<CompleteOrderResult>;
  createSession?: Maybe<Session>;
  deleteSession?: Maybe<Scalars['Boolean']['output']>;
  updateOrderInSession?: Maybe<Session>;
};


export type MutationCompleteOrderPaymentArgs = {
  billingName: Scalars['String']['input'];
  id: Scalars['ID']['input'];
  paymentToken: Scalars['String']['input'];
};


export type MutationDeleteSessionArgs = {
  sessionId: Scalars['ID']['input'];
};


export type MutationUpdateOrderInSessionArgs = {
  id: Scalars['ID']['input'];
  order: OrderInput;
};

export type Order = {
  __typename?: 'Order';
  billingEmailAddress?: Maybe<Scalars['String']['output']>;
  billingName?: Maybe<Scalars['String']['output']>;
  billingPhoneNumber?: Maybe<Scalars['String']['output']>;
  companyOrContactPerson: Scalars['String']['output'];
  deliveryInfo?: Maybe<DeliveryInfo>;
  dietaryPreferences: DietaryPreferences;
  fulfillmentMethod: FulfillmentMethod;
  headcount: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  idempotencyKey?: Maybe<Scalars['String']['output']>;
  mealType: MealType;
  memo?: Maybe<Scalars['String']['output']>;
  menuItems: Array<MenuItem>;
  orderDateTime: Scalars['String']['output'];
  paymentMethod: Scalars['String']['output'];
  pickupOrDeliveryDateTime: Scalars['String']['output'];
  preCartMenuItems: Array<MenuItem>;
  recipientEmailAddress?: Maybe<Scalars['String']['output']>;
  recipientIsPayer?: Maybe<Scalars['Boolean']['output']>;
  recipientName?: Maybe<Scalars['String']['output']>;
  recipientPhoneNumber?: Maybe<Scalars['String']['output']>;
  servingStyle: ServingStyle;
  squareOrderId?: Maybe<Scalars['String']['output']>;
  state: OrderStatus;
  subtotal: Scalars['Float']['output'];
  tax: Scalars['Float']['output'];
  tip: Scalars['Float']['output'];
  total: Scalars['Float']['output'];
};

export type OrderInput = {
  billingEmailAddress?: InputMaybe<Scalars['String']['input']>;
  billingName?: InputMaybe<Scalars['String']['input']>;
  billingPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  companyOrContactPerson?: InputMaybe<Scalars['String']['input']>;
  deliveryInfo?: InputMaybe<DeliveryInfoInput>;
  dietaryPreferences?: InputMaybe<DietaryPreferencesInput>;
  fulfillmentMethod?: InputMaybe<FulfillmentMethod>;
  headcount?: InputMaybe<Scalars['Int']['input']>;
  idempotencyKey?: InputMaybe<Scalars['String']['input']>;
  mealType?: InputMaybe<MealType>;
  memo?: InputMaybe<Scalars['String']['input']>;
  menuItems?: InputMaybe<Array<InputMaybe<MenuItemInput>>>;
  orderDateTime: Scalars['String']['input'];
  paymentMethod?: InputMaybe<Scalars['String']['input']>;
  pickupOrDeliveryDateTime: Scalars['String']['input'];
  preCartMenuItems?: InputMaybe<Array<InputMaybe<MenuItemInput>>>;
  recipientEmailAddress?: InputMaybe<Scalars['String']['input']>;
  recipientIsPayer?: InputMaybe<Scalars['Boolean']['input']>;
  recipientName?: InputMaybe<Scalars['String']['input']>;
  recipientPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  servingStyle?: InputMaybe<ServingStyle>;
  squareOrderId?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<OrderStatus>;
  subtotal?: InputMaybe<Scalars['Float']['input']>;
  tax?: InputMaybe<Scalars['Float']['input']>;
  tip?: InputMaybe<Scalars['Float']['input']>;
  total?: InputMaybe<Scalars['Float']['input']>;
};

export enum OrderStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Draft = 'DRAFT',
  Fulfilled = 'FULFILLED',
  Open = 'OPEN'
}

export type Query = {
  __typename?: 'Query';
  getSession?: Maybe<Session>;
  getSquareCatalog?: Maybe<Array<Maybe<SquareItem>>>;
  getSquareCatalogItemsByCategory?: Maybe<Array<Maybe<SquareItem>>>;
};


export type QueryGetSessionArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetSquareCatalogItemsByCategoryArgs = {
  categoryId: Scalars['String']['input'];
};

export enum ServingStyle {
  Boxes = 'Boxes',
  Buffet = 'Buffet',
  Unset = 'Unset'
}

export type Session = {
  __typename?: 'Session';
  createdAt: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  order?: Maybe<Order>;
};

export type SquareItem = {
  __typename?: 'SquareItem';
  categories?: Maybe<Array<Scalars['String']['output']>>;
  category?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  dietary?: Maybe<Array<Scalars['String']['output']>>;
  imageUrl: Scalars['String']['output'];
  itemId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  popular?: Maybe<Scalars['Boolean']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
  servings?: Maybe<Scalars['Int']['output']>;
};

export type GetSquareCatalogQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSquareCatalogQuery = { __typename?: 'Query', getSquareCatalog?: Array<{ __typename?: 'SquareItem', itemId: string, name: string, price?: number | null, imageUrl: string, description: string, category?: string | null, categories?: Array<string> | null, popular?: boolean | null, dietary?: Array<string> | null, servings?: number | null } | null> | null };


export const GetSquareCatalogDocument = gql`
    query GetSquareCatalog {
  getSquareCatalog {
    itemId
    name
    price
    imageUrl
    description
    category
    categories
    popular
    dietary
    servings
  }
}
    `;

/**
 * __useGetSquareCatalogQuery__
 *
 * To run a query within a React component, call `useGetSquareCatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSquareCatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSquareCatalogQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSquareCatalogQuery(baseOptions?: Apollo.QueryHookOptions<GetSquareCatalogQuery, GetSquareCatalogQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSquareCatalogQuery, GetSquareCatalogQueryVariables>(GetSquareCatalogDocument, options);
      }
export function useGetSquareCatalogLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSquareCatalogQuery, GetSquareCatalogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSquareCatalogQuery, GetSquareCatalogQueryVariables>(GetSquareCatalogDocument, options);
        }
export function useGetSquareCatalogSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetSquareCatalogQuery, GetSquareCatalogQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetSquareCatalogQuery, GetSquareCatalogQueryVariables>(GetSquareCatalogDocument, options);
        }
export type GetSquareCatalogQueryHookResult = ReturnType<typeof useGetSquareCatalogQuery>;
export type GetSquareCatalogLazyQueryHookResult = ReturnType<typeof useGetSquareCatalogLazyQuery>;
export type GetSquareCatalogSuspenseQueryHookResult = ReturnType<typeof useGetSquareCatalogSuspenseQuery>;
export type GetSquareCatalogQueryResult = Apollo.QueryResult<GetSquareCatalogQuery, GetSquareCatalogQueryVariables>;