// components/OrderItems.tsx
import React from 'react';
import { useCart } from './CartContext'; // Adjust the path if necessary
import { MenuItem } from 'src/graphql/generated-types';
import { useSession } from './SessionContext';

function OrderItems() {
  const { cartItems, updateCartItem } = useCart();
  const { navigateWithSession } = useSession();
  const handleQuantityChange = (item: MenuItem, event: React.ChangeEvent<HTMLInputElement>) => {
    const newQty = parseInt(event.target.value);
    updateCartItem(item.squareItemId, newQty);
  };


  // const totalCost = cartItems.reduce((sum, item) => sum + (item.price) * item.quantity, 0);

  return (
    <div>
      <div className="">
        <div className="font-bold flex justify-between">
          <div className="">Quantity</div>
          <div className=""></div>
          <div className="s">Item subtotal</div>
        </div>
        {/* <div id="checkout_orderContents"> */}
          {cartItems.map(item => (
            <div key={item.squareItemId} className="flex justify-between border-b border-gray-500 pt-1">
              <div className="">  {/* qty */}
                <div>{item.quantity}</div>
              </div>
              <div className="grow pl-14">  {/* item name */}
                {item.name}
              </div>

              <div className="">{(item.quantity * item.price).toFixed(2)}</div>{/* item level subtotal */}
            </div>
          ))}
        {/* </div> */}
      </div>

      <div className="clear itemRow textTaupe subtotalRow noBorder">
        {/* <div className="col bold floatRight">
          Subtotal &nbsp;
          <span className="checkout_totalPrice">{totalCost.toFixed(2)}</span>
        </div>
      </div>
      <div className="clear itemRow textTaupe noBorder pad0"> */}

      </div>
    </div>
  );
}

export default OrderItems;
