import veganIcon from "../../assets/vegan_icon.png";
import vegetarianIcon from "../../assets/vegetarian_icon.png";
import glutenIcon from "../../assets/gluten_icon.svg";
import { MenuItem } from "src/graphql/generated-types";

interface MenuItemDisplayProps {
  menuItem: MenuItem;
}

export const MenuItemPlusDietary: React.FC<MenuItemDisplayProps> = ({ menuItem }) => {
  return (
    <div className="flex flex-row items-center text-sm text-gray-900">
      {menuItem.name}
      {menuItem.dietary?.includes('Vegan') && <img src={veganIcon} title="Vegan" className="w-4 ml-2" />}
      {menuItem.dietary?.includes('Vegetarian') && !menuItem.dietary?.includes('Vegan') && <img src={vegetarianIcon} title="Vegetarian" className="w-4 ml-2" />}
      {!menuItem.dietary?.includes('Gluten Free') && <img src={glutenIcon} title="Contains gluten" className="w-5 ml-2" />}
    </div>
  );
}
