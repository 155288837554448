
// interface MenuItem {
//   itemId: string;
//   imageUrl: string;
//   name: string;
//   price: number;
// }

import { MenuItem } from "src/graphql/generated-types";

interface MenuCategoryProps {
  category: string;
  items: MenuItem[];
  addItemToCart: (item: MenuItem) => void;
}

function MenuCategory({ category, items, addItemToCart }: MenuCategoryProps) {
  return (
    <div className="p-2">
      <h2>{category}</h2>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '1rem' }}>
        {items.map(item => (
          <div key={item.squareItemId} className="w-48 h-48 overflow-hidden relative">
            {/* {item.imageUrl && ( */}
              <img className="min-w-full min-h-full object-cover absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" src={item.imageUrl || "http://place-hold.it/222x225?text=Imagine Delicious!&fontsize=16"} alt={item.name} />
            {/* )} */}
            <div className="absolute top-0 left-0 p-1 text-orange-100 text-shadow">
              {item.name}
            </div>
            {/* Call addItemToCart with current item on button click */}
            {/* <button className="w-full bg-orange-500 text-white p-2 m-0">Order</button> */}
            <button className="absolute bottom-0 left-0 w-full bg-orange-500 text-white m-0" onClick={() => addItemToCart(item)}>Order</button>

          </div>
        ))}
      </div>
    </div>
  );
}

export default MenuCategory;

// import React from 'react';

// interface MenuItem {
//   itemId: string;
//   imageUrl: string;
//   name: string;
//   price: number;
// }

// interface MenuCategoryProps {
//   category: string;
//   items: MenuItem[];
// }

// function MenuCategory({ category, items }: MenuCategoryProps) {
//   return (
//     <div className="p-2">
//       <h2>{category}</h2>
//       <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '1rem' }}>
//         {items.map(item => (
//           <div key={item.itemId} className="overflow-hidden">
//             <div className="w-full h-48 relative">
//               <img className="w-full h-full object-cover" src={item.imageUrl || "http://place-hold.it/222x225?text=Imagine Delicious!&fontsize=16"} alt={item.name} />
//               <div className="absolute top-0 left-0 p-1 text-white" style={{ textShadow: '1px 1px 2px black' }}>
//                 {item.name}
//               </div>
//             </div>
//             <button className="w-full bg-orange-500 text-white p-2 m-0">Order</button>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default MenuCategory;



