import React, { useEffect, useState } from 'react';
import boxedImage from '../../assets/boxed.webp';  
import buffetImage from '../../assets/buffet.jpg'; 
import { ImageContainer, StyledButton, ButtonText } from './StyledComponents';
import { Order, ServingStyle } from 'src/graphql/generated-types';
import { useSession } from './SessionContext';

type servingStyleProps = {
  onValueChange: (value: ServingStyle) => void;
};

const ServingStyleComponent: React.FC<servingStyleProps> = ({ onValueChange }) => {
  const [servingStyle, setServingStyle] = useState<ServingStyle | string>('');
  const { session, setSession } = useSession();

  // select appropriate meal time based on session.order.mealTime in a useEffect hook
  useEffect(() => {
    if (session?.order?.servingStyle) {
      handleServingStyleSelection(session.order.servingStyle);
    }
  }, [session?.order?.servingStyle]);

  const handleServingStyleSelection = (servingStyle: ServingStyle) => {
    setServingStyle(servingStyle);
    onValueChange(servingStyle);
    if (session) {
      const updatedOrder = {  
        ...session.order,
        servingStyle: servingStyle
      };
      setSession({
        ...session,
        order: updatedOrder as Order
      });
    }
  };

  return (
    <div>
      <ImageContainer>
        <StyledButton onClick={() => handleServingStyleSelection(ServingStyle.Boxes)} style={
          {
            backgroundImage: `url(${boxedImage})`,
            border: servingStyle === ServingStyle.Boxes ? '8px solid orange' : 'none'
          }}>
          <ButtonText>Boxed</ButtonText>
        </StyledButton>
      </ImageContainer>

      <ImageContainer>
        <StyledButton onClick={() => handleServingStyleSelection(ServingStyle.Buffet)} style={
          {
            backgroundImage: `url(${buffetImage})`,
            border: servingStyle === ServingStyle.Buffet ? '8px solid orange' : 'none'
          }}>

          <ButtonText>Buffet</ButtonText>
        </StyledButton>
      </ImageContainer>
    </div>
  );
};

export default ServingStyleComponent;
