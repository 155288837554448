// // 

// import React, { useState } from "react";

// const Comp1: React.FC = () => (
//     <div>
//         <h1>Comp1</h1>
//     </div>
// );

// const Comp2: React.FC = () => (
//     <div>
//         <h1>Comp2</h1>
//     </div>
// );

// const components = [Comp1, Comp2];

// const CompShell: React.FC = () => {
//     const [compIdx, setCompIdx] = useState<number>(0);

//     const toggleComponent = () => {
//         setCompIdx(prevIdx => prevIdx ^ 1);
//     };

//     const SelectedComponent = components[compIdx];

//     return (
//         <div>
//             <h1>CompShell</h1>
//             <button onClick={toggleComponent}>Change component</button>
//             <div>
//                 {/* Content Area */}
//                 <SelectedComponent />
//             </div>
//         </div>
//     );
// };

// export default CompShell;

import React, { useState } from "react";

// Define a type for your props if needed
interface CompProps {
    someProp: string;
    anotherProp: number;
}

const Comp1: React.FC<CompProps> = ({ someProp, anotherProp } : CompProps) => (
    <div>
        <h1>Comp1 - Prop: {someProp}</h1>
        <h1>Num = {anotherProp}</h1>
    </div>
);

const Comp2: React.FC<CompProps> = ({ someProp, anotherProp }) => (
    <div>
        <h1>Comp2 - Prop: {someProp}</h1>
        <h1>Number = {anotherProp}</h1>
    </div>
);

const components = [Comp1, Comp2];

const CompShell: React.FC = () => {
    const [compIdx, setCompIdx] = useState<number>(0);
    const [commonProps, setCommonProps] = useState<CompProps>({
        someProp: "Hello World",
        anotherProp: 42
    });

    const toggleComponent = () => {
        commonProps.anotherProp = Math.random();
        setCommonProps(commonProps);
        setCompIdx(prevIdx => prevIdx ^ 1);
    };

    const SelectedComponent = components[compIdx];



    return (
        <div>
            <h1>CompShell</h1>
            <button onClick={toggleComponent}>Change component</button>
            <div>
                {/* Content Area */}
                <SelectedComponent {...commonProps} />
            </div>
        </div>
    );
};

export default CompShell;
