import BillingInfoCreditCard, { BillingInfo } from "./CateringHelper/BillingInfoCreditCard";
import CheckoutSteps, { CheckoutStep, CheckoutStepStatus } from "./CateringHelper/CheckoutSteps";

export default function StyleReferencePage() {


    const checkoutSteps: CheckoutStep[] = [
        {
            id: 1,
            name: "Add to Cart",
            navigationLocation: "/step1",
            status: CheckoutStepStatus.Complete
        },
        {
            id: 2,
            name: "Pay",
            navigationLocation: "/step2",
            status: CheckoutStepStatus.Current
        },
        {
            id: 3,
            name: "Order Summary",
            navigationLocation: "/step3",
            status: CheckoutStepStatus.Upcoming
        }
    ];
    return (
        <div className="flex flex-col min-w-[600px]">
            <div className="p-2 flex gap-1 ">
                <div className="flex flex-1 flex-col p-4 border-gray-500 border rounded">
                     <h2 className="text-center m-0 p-0">Text</h2>
                    <h1>Heading 1</h1>
                    <h2>Heading 2</h2>
                    <h3>Heading 3</h3>
                    <h4>Heading 4</h4>
                    <h5>Heading 5</h5>
                    <p>Good evening, London. Allow me first to apologize for this interruption. 
                    I do, like many of you, appreciate the comforts of every day routine – the 
                    security of the familiar, the tranquility of repetition.</p>
                </div>
                {/* <div className="flex flex-col flex-1 p-4"> */}
                    <div className="flex flex-col  flex-1 text-center  border-gray-500 border rounded">
                        <h2>Colors</h2>
                        <div className=" bg-primary">Primary Theme Color</div>
                        <div className=" bg-secondary">Secondary Theme Color</div>
                        <div className=" bg-secondary_highlight">Secondary Highlight Color</div>
                        <div className=" bg-secondary_dark text-white">Secondary Dark Color</div>
                    </div>
                {/* </div> */}
                <div className="flex flex-col flex-1  border-gray-500 border rounded">
                    <div className="flex flex-col text-center">
                        <h2>Elements</h2>
                        <button>Standard Button</button>
                    </div>
                </div>
            </div>
            <div className="m-2 mt-0 p-2 pt-0 border-gray-500 border rounded">
                <h2>Checkout Steps</h2>
                <CheckoutSteps steps={checkoutSteps} navigateFunction={() => { }} />
            </div>
            <BillingInfoCreditCard onSubmit={function (billingInfo: BillingInfo): void {
                throw new Error("Function not implemented.");
            } } />
        </div>
    );
}