// components/OverlayPane.tsx
import React, { useRef, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';  // Import the close icon

interface OverlayPaneProps {
    item: {
        imageUrl: string;
        name: string;
        description: string;
        price: number;
        squareItemId: string;
    };
    onClose: () => void;
}

const OverlayPane: React.FC<OverlayPaneProps> = ({ item, onClose }) => {
    const overlayContentRef = useRef<HTMLDivElement>(null);
    item.imageUrl = item.imageUrl || 'https://via.placeholder.com/150';
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (overlayContentRef.current && !overlayContentRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <div className="overlay">
            <div className="overlayContent" ref={overlayContentRef}>
                <button className="closeButton" onClick={onClose}>
                    <FaTimes size={24} color="red"/>  {/* Use the close icon here */}
                </button>
                <img src={item.imageUrl} alt={item.name} className="overlayImage" />
                <div>{item.name}</div>
                <div className='overlayDescription'>{item.description}</div>
                <p>${(item.price).toFixed(2)}</p>
            </div>
        </div>
    );
}

export default OverlayPane;

