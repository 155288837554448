import { gql } from 'graphql-tag';

export const CREATE_SESSION = gql`
  mutation CreateNewSession {
    createSession {
      id
      createdAt
      order {
        id
        headcount
        mealType
        servingStyle
        dietaryPreferences {
          vegetarianMeals
          veganMeals
          glutenFreeMeals
        }
        menuItems {
          squareItemId
          quantity
          name
          price
          description
          imageUrl
          category
        }
        preCartMenuItems {
          squareItemId
          quantity
          name
          price
          description
          imageUrl
          category
        }
        fulfillmentMethod
        deliveryInfo {
          street
          city
          province
          postalCode
          date
          time
          instructions
          deliveryCharge
        }
        subtotal
        tax
        tip
        total
        paymentMethod
        orderDateTime
        recipientName
        recipientPhoneNumber
        recipientEmailAddress
        billingName
        billingPhoneNumber
        billingEmailAddress
        pickupOrDeliveryDateTime
        companyOrContactPerson
        memo
        idempotencyKey
        squareOrderId
        state
        recipientIsPayer
      }
    }
  }
`;
export const COMPLETE_ORDER_PAYMENT = gql`
  mutation CompleteOrderPayment($sessionId: ID!, $billingName:String!, $paymentToken: String!) {
    completeOrderPayment(id: $sessionId, billingName: $billingName, paymentToken: $paymentToken) {
      success
      failureReason 
    }
  }
`;

export const UPDATE_ORDER_IN_SESSION = gql`
mutation UpdateOrderInSession($sessionId: ID!, $order: OrderInput!) {
  updateOrderInSession(id: $sessionId, order: $order) {
    id
  } 
}
`;