// A static class for string manipulation
export class NumberUtils {
    // Returns true if the string is null, undefined, or empty
    public static isNullOrEmpty(str: string | null | undefined): boolean {
        return str === null || str === undefined || str === "";
    }

    // Title cases a string
    public static toDollars(num: number): string {
        return `$${parseFloat((num).toString()).toFixed(2)}`
    }

}