import { SquareItem, MenuItem } from '../graphql/generated-types';
export class MenuItemFactory {
    static fromSquareItem(squareMenuItem: SquareItem): MenuItem {
        let menuItem : MenuItem = {
            name: squareMenuItem.name,
            quantity: 0,
            squareItemId: squareMenuItem.itemId,
            category: squareMenuItem.category || '',
            categories: squareMenuItem.categories || [],
            imageUrl: squareMenuItem.imageUrl || '',
            description: squareMenuItem.description || '',
            price: squareMenuItem.price || 0,
            dietary: squareMenuItem.dietary || []
        }
       return menuItem
    }
}