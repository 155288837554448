// Top level App.tsx component
import './tailwind.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { CartProvider } from './components/CateringHelper/CartContext'; // Adjust the path
import { SessionProvider } from './components/CateringHelper/SessionContext';
import { BusinessProvider } from './components/BusinessContext';
import CalendarTest from './components/testbed/CalendarTest';
import AppShell from './components/AppShell';
import CompSwap from './components/testbed/CompSwap';
import CateringHelperShell from './components/CateringHelper/CateringHelperShell';
import StyleReferencePage from './components/StyleReferencePage';

console.log('process.env.APP_ENV :', process.env.REACT_APP_ENV);
let apolloClientURI = process.env.REACT_APP_ENV === 'production' ? 'https://app.spicyroysgrill.com/graphql' : 'http://localhost:4000/graphql';
const client = new ApolloClient({
  // uri: 'http://192.168.86.28:4000/graphql', // Replace with your GraphQL server's URI
  // uri: 'http://localhost:4000/graphql', // Replace with your GraphQL server's URI
  uri: apolloClientURI, // Replace with your GraphQL server's URI
  cache: new InMemoryCache()
});

function App() {
  return (
    <ApolloProvider client={client}>
      <BusinessProvider>
        <Router>
          <SessionProvider>
            <CartProvider>
              <Routes>
                {/* <Route path="/tictactoe" element={<Game />}></Route> */}
                <Route path="/compswap" element={<CompSwap />}></Route>
                <Route path="/" element={<h1>Welcome to Catering Commander!</h1>} />
                <Route path="/cal" element={<CalendarTest />} />
                <Route path="/styles" element={<StyleReferencePage />} />
                <Route path="/app" element={<AppShell />}>
                <Route path="catshell" element={<CateringHelperShell />} />
                </Route>
              </Routes>
            </CartProvider>
          </SessionProvider>
        </Router>
      </BusinessProvider>
    </ApolloProvider>
  );
}

export default App;
