import { MathUtils } from 'src/utilities/MathUtils';
import { useSession } from './SessionContext'; 
import { Order } from 'src/graphql/generated-types';
import { LocationInfo } from 'shared/LocationInfo';

export interface HeadcountProps {
  headcount: number;
  setHeadcount: (value: number) => void;

}
function Headcount({ headcount, setHeadcount }: HeadcountProps) {
  const { session, setSession } = useSession();
  // setHeadcount(headcount);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/\D/g, '');
    // const value = event.target.value;
    let newHeadcount = value ? parseInt(value) : 1;
    newHeadcount = MathUtils.clamp(newHeadcount, 1, LocationInfo.maxBoxesPerMealtime);
    console.log(`Setting the head count from ${headcount} to ${newHeadcount}`);
    setHeadcount(newHeadcount);

    // Update the session context
    if (session) {
      const updatedOrder = {  
        ...session.order,
        headcount: newHeadcount
      };
      setSession({
        ...session,
        order: updatedOrder as Order
      });
    }
  };      
  
  function handleResetSession(): void {
    const url = new URL(window.location.origin + window.location.pathname);
    console.log('url:', url);
    window.history.pushState({}, '', url);
    window.location.reload();
  }

  return (
    <div className="text-sm">
      <div>Select Headcount
        {/* Only allow numbers to be entered */}
      <input
        type="number"
        className='w-16 text-sm m-2'
        id="attendeesCt"
        pattern="[0-9]*"
        min="1"
        max="400"
        name="attendeesCt"
        placeholder="e.g. 15"
        value={headcount || 1}
        onChange={handleInputChange}
      />

      <button onClick={handleResetSession}>reset session</button>
    </div>
    </div>
  );
}

export default Headcount;
