import { SquareItem, MenuItem, ServingStyle } from '../graphql/generated-types';
import { CCMenuItem } from './CCMenuItem';
import { MenuItemFactory } from './MenuItemFactory';


// Track which square items are for full and half pans
type PanServings = {
  halfpan?: SquareItem;
  fullpan?: SquareItem;
};
type panTypes = 'halfpan' | 'fullpan';
export enum DietaryPreference {
  Meat = 'Meat',
  Vegetarian = 'Vegetarian',
  Vegan = 'Vegan',
  GlutenFree = 'Gluten Free',
  DairyFree = 'Dairy Free'
}


/**
 * This class builds a recommended catering order based on headcount and serving style preferences.
 */
export class OrderRecommender {
  private headcount: number;
  private servingStyle: ServingStyle;
  private squareCatalogItems: SquareItem[];
  private preCartMenuItems: MenuItem[];
  private _allCateringMenuItems: MenuItem[];
  public get allCateringMenuItems(): MenuItem[] {
    return this._allCateringMenuItems;
  }
  private _buffetMenuItemSortOrderMeat = Array<string>();
  public get buffetMenuItemSortOrderMeat() {
    return this._buffetMenuItemSortOrderMeat;
  }
  public set buffetMenuItemSortOrderMeat(value) {
    this._buffetMenuItemSortOrderMeat = value;
  }
  private _buffetMenuItemSortOrderVegetarian = Array<string>();
  public get buffetMenuItemSortOrderVegetarian() {
    return this._buffetMenuItemSortOrderVegetarian;
  }
  private panServingsMap: { [key: string]: PanServings } = {};

  // The sides that accompany all entrees
  private _buffetStandardSides: Array<string>;
  public get buffetStandardSides(): Array<string> {
    return this._buffetStandardSides;
  }
  constructor(headcount: number, servingStyle: ServingStyle, squareCatalog: Array<SquareItem>, preCartMenuItems: MenuItem[]) {
    this.headcount = headcount;
    this.servingStyle = servingStyle;
    this.squareCatalogItems = squareCatalog;
    this.preCartMenuItems = preCartMenuItems; // do we need this?
    this._allCateringMenuItems = this.squareCatalogItems?.filter((item: SquareItem) => item?.category?.toLowerCase() === 'catering').map((item: SquareItem) => new CCMenuItem(item)) || []
    this._buffetMenuItemSortOrderMeat = [
      "^Jerk Chicken,",
      "Curry Chicken",
      "Island Jerk Chicken Salad",
      "Jerk Chicken Breast",
      "Jerk Chicken Burrito"
    ]

    this._buffetMenuItemSortOrderVegetarian = [
      "Curry Tofu",
      "Jerk Tofu"
    ]

    this._buffetStandardSides = [
      "Rice and Peas",
      "Plantains",
      "House Salad"
    ];
    // Build pan servings map
    this.buildPanServingsMap();
  }

  /**
 * This method will sort the menu items based on the buffetMenuItemSortOrder array
 * and append the rest of the menuItems to the end of the returned array.
 * @param menuItems 
 * @returns preferred menu items sorted first, then the rest of the menu items
 */
  private sortMenuItems(sortOrder: Array<string>, menuItems: SquareItem[]) {
    // copy the menuItems array 
    if (!menuItems || !menuItems.length) return [];

    // Only include the items in the sortOrder array from menuItems
    let sortedMenuItems: CCMenuItem[] = [];
    let patterns = sortOrder.map(e => new RegExp(e.toLowerCase()));
    patterns.map((pattern) => {
      menuItems.forEach(item => {
        if (item.popular && pattern.test(item.name.toLowerCase())) {
          sortedMenuItems.push(new CCMenuItem(item))
        }
      })
    });

    return sortedMenuItems;
  }
  /** 
   * Build a pan servings map based on the square catalog items
  */
  private buildPanServingsMap() {
    // Parse apart each square item name to item name and pan size
    if (!this.squareCatalogItems || !this.squareCatalogItems.length) return;
    let buffetMenuItems = this.squareCatalogItems?.filter((item: SquareItem) => item?.category?.toLowerCase() === 'catering');
    buffetMenuItems.forEach((item: SquareItem) => {
      if (!item) return;
      let origName = item.name.toLowerCase();

      // Get rid of those pesky commas
      let name = origName.replaceAll(',', '');

      // extract off just the name using "Half Pan" or "Full Pan" as the delimiter
      if (name.includes('half pan')) {
        name = name.split('half pan')[0].trim();
      }
      else if (name.includes('full pan')) {
        name = name.split('full pan')[0].trim();
      }
      if (!this.panServingsMap[name]) {
        this.panServingsMap[name] = {
          halfpan: undefined,
          fullpan: undefined
        };
      }
      let panSize: panTypes = 'halfpan' as panTypes;
      if (origName.includes('full pan')) {
        panSize = 'fullpan' as panTypes;
      }
      this.panServingsMap[name][panSize] = item || undefined;
    });
  }
  /**
   * This method will calculate the recommended quantities for each menu item based on the headcount and serving style
   * and update the precartMenuItems accordingly.  It will also return them.
   * 
   * Business Rules:
   * 1. If the serving style is Buffet, then we need to determine the number of entree full and half pans.
   * 2. We will filter only on the most popular entrees for meat, vegetarian and vegatarian separately.
   * 3. We will recommend sides based on the headcount (rice, plantains and salad)
   * 
   * @returns the updated preCartMenuItems
   */
  public calculateRecommendedQuantities(dietaryPreference: DietaryPreference, headcount?: number): MenuItem[] {
    headcount = headcount ? headcount : this.headcount;
    // If the headcount is 0, then we don't need to do anything
    if (headcount) {

      // If the serving style is Buffet, then we need to determine the number of entree full and half pans.
      if (this.servingStyle === ServingStyle.Buffet) {
        return this.calculateBuffetQuantities(dietaryPreference, headcount);
      }
      else if (this.servingStyle === ServingStyle.Boxes) {
        return this.calculateBoxMealQuantites(dietaryPreference, headcount);
      }
    }
    return [];
  }

  /**
   * This method will calculate the recommended quantities for each menu item based on the headcount and serving style
   * and update the precartMenuItems accordingly.  It will also return them.
   * 
   * Business Rules:
   * 1. If the serving style is Buffet, then we need to determine the number of entree full and half pans.
   * 2. We will filter only on the most popular entrees for meat, vegetarian and vegatarian separately.
   * 3. We will recommend sides based on the headcount (rice, plantains and salad)
   * 
   * @returns the updated preCartMenuItems
   */
  private calculateBuffetQuantities(dietaryPreference: DietaryPreference, headcount: number): MenuItem[] {
    // Hard code this for now.  We will need to make this dynamic in the future
    dietaryPreference = DietaryPreference.Meat;

    type PanCounts = {
      halfpans: {
        count: number;
        squareItem?: SquareItem;
      },
      fullpans: {
        count: number;
        squareItem?: SquareItem;
      }
    }
    let panCounts: { [key: string]: PanCounts } = {};
    let sortOrder = dietaryPreference === DietaryPreference.Meat ? this.buffetMenuItemSortOrderMeat : this.buffetMenuItemSortOrderVegetarian;
    let buffetMenuItems = this.squareCatalogItems?.filter((item: any) => item?.category.toLowerCase() === 'catering');
    let popularSortedMenuItems = this.sortMenuItems(sortOrder, buffetMenuItems);
    let preCartMenuItems: MenuItem[] = [];

    // 1. We need to iterate through the menu items and add half pans of each menu item to the order until we run out of headcount
    // let tempHeadcount = this.headcount;
    let servingsLeft = headcount;

    // Calculate pan counts
    // while (servingsLeft > 0) {
    //   for (const [itemName, panServings] of Object.entries(this.panServingsMap)) {
    //     if (!popularSortedMenuItems.find((item: CCMenuItem) => item.name.toLowerCase().includes(itemName.toLowerCase()))) {
    //       console.log('skipping', itemName);
    //       continue;
    //     } else {
    //       console.log('adding', itemName);
    //     }
    //     // If the item is not one of our desired popular items
    //     let fullPanServings = panServings.fullpan?.servings || 1;
    //     let halfPanServings = panServings.halfpan?.servings || 1;
    //     if (panCounts[itemName] === undefined) {
    //       panCounts[itemName] = {
    //         halfpans: {
    //           count: 1,
    //           squareItem: panServings.halfpan
    //         },
    //         fullpans: {
    //           count: 0,
    //           squareItem: panServings.fullpan
    //         }
    //       }
    //       servingsLeft -= halfPanServings;
    //     }
    //     else {
    //       if (panCounts[itemName].halfpans.count > 0) {
    //         panCounts[itemName].fullpans.count++;
    //         panCounts[itemName].halfpans.count--;
    //         servingsLeft += halfPanServings;
    //         servingsLeft -= fullPanServings;
    //       }
    //       else {
    //         panCounts[itemName].halfpans.count++;
    //         servingsLeft -= halfPanServings;
    //       }
    //     }
    //     if (servingsLeft <= 0) {
    //       break;
    //     }
    //   }
    // }

    // Calculate pan counts (take 2)
    while (servingsLeft > 0) {
      // for (const [itemName, panServings] of Object.entries(this.panServingsMap)) {
      let popularItemSet: Set<string> = new Set();
      popularSortedMenuItems
        .forEach(item => {
          let itemName = item.name.toLowerCase().split(",")[0];
          popularItemSet.add(itemName);
        });

      // convert set to an array
      let popularItems = Array.from(popularItemSet);
      for (const popularItem of popularItems) {
        let panServings = this.panServingsMap[popularItem];
        if (!panServings) {
          console.log('skipping', popularItem);
          continue;
        } else {
          console.log('adding', popularItem);
        }
        // If the item is not one of our desired popular items
        let fullPanServings = panServings.fullpan?.servings || 1;
        let halfPanServings = panServings.halfpan?.servings || 1;
        if (panCounts[popularItem] === undefined) {
          panCounts[popularItem] = {
            halfpans: {
              count: 1,
              squareItem: panServings.halfpan
            },
            fullpans: {
              count: 0,
              squareItem: panServings.fullpan
            }
          }
          servingsLeft -= halfPanServings;
        }
        else {
          if (panCounts[popularItem].halfpans.count > 0) {
            panCounts[popularItem].fullpans.count++;
            panCounts[popularItem].halfpans.count--;
            servingsLeft += halfPanServings;
            servingsLeft -= fullPanServings;
          }
          else {
            panCounts[popularItem].halfpans.count++;
            servingsLeft -= halfPanServings;
          }
        }
        if (servingsLeft <= 0) {
          break;
        }
      };
    }
    for (const [itemName] of Object.entries(panCounts)) {
      console.log(`panCounts[${itemName}] = ${JSON.stringify(panCounts[itemName], null, 2)}`);
      this.createNewCartItemFromPanCounts(panCounts[itemName].halfpans, preCartMenuItems);
      this.createNewCartItemFromPanCounts(panCounts[itemName].fullpans, preCartMenuItems);
    }

    // 2. Now add in the standard sides to match the headcount.
    this.buffetStandardSides.forEach((side: string) => {
      side = side.toLowerCase();
      let key = Object.keys(this.panServingsMap).find((itemName: string) => itemName.toLowerCase().includes(side.toLowerCase()));
      if (!key) return;
      let sideItem = this.panServingsMap[key];
      if (sideItem) {
        // full pan servings are the headcount divided by the servings per full pan floored
        let fullPanServings = Math.round(headcount / (this.panServingsMap[key]?.fullpan?.servings || 1));

        // half pan servings are the headcount minus the full pan servings times the servings per full pan
        let halfPanServings = Math.round((headcount - (this.panServingsMap[key]?.fullpan?.servings || 0) * fullPanServings) / (this.panServingsMap[key]?.halfpan?.servings || 1));

        // let halfPanServings = Math.floor((this.panServingsMap[sideItem]?.halfpan?.servings || 1) / headcount);
        if (panCounts[side] === undefined) {
          panCounts[side] = {
            halfpans: {
              count: 0,
              squareItem: sideItem.halfpan
            },
            fullpans: {
              count: 0,
              squareItem: sideItem.fullpan
            }
          }
        }
        panCounts[side].fullpans.count = fullPanServings;
        panCounts[side].halfpans.count = halfPanServings;
      }
      if (panCounts[side].halfpans.count > 0 && sideItem.halfpan) {
        let newItem = MenuItemFactory.fromSquareItem(sideItem.halfpan);
        newItem.quantity = panCounts[side].halfpans.count;
        preCartMenuItems.push(newItem);
      }
      if (panCounts[side].fullpans.count > 0 && sideItem.fullpan) {
        let newItem = MenuItemFactory.fromSquareItem(sideItem.fullpan);
        newItem.quantity = panCounts[side].fullpans.count;
        preCartMenuItems.push(newItem);
      }
    });

    return preCartMenuItems;
  }

  private createNewCartItemFromPanCounts(currentItemPans: { count: number; squareItem?: SquareItem | undefined; }, preCartMenuItems: MenuItem[]) {
    if (currentItemPans.count > 0 && currentItemPans.squareItem) {
      let newItem = MenuItemFactory.fromSquareItem(currentItemPans.squareItem);
      // new CCMenuItem(currentItemPans.squareItem);
      newItem.quantity = currentItemPans.count;
      preCartMenuItems.push(newItem);
    }
  }

  private calculateBoxMealQuantites(DietaryPreference: DietaryPreference, headcount: number): MenuItem[] {
    return [];
  }
}