// components/Checkout.tsx
import React from 'react';
// import '../css/checkout.css';
import { useCart } from './CartContext'; // Adjust the path if necessary
import { MenuItem } from 'src/graphql/generated-types';
import { useSession } from './SessionContext';
import { CateringHelperShellProps } from './CateringHelperShell';

function CheckoutFinalizeQuantities({ navigateFunction }: CateringHelperShellProps) {
  const { cartItems, updateCartItem, removeCartItem } = useCart();
  const { navigateWithSession } = useSession();
  const handleQuantityChange = (item: MenuItem, event: React.ChangeEvent<HTMLInputElement>) => {
    const newQty = parseInt(event.target.value);
    updateCartItem(item.squareItemId, newQty);
  };

  const handleRemoveItem = (itemId: string) => {
    removeCartItem(itemId);
  };
  const totalCost = cartItems.reduce((sum, item) => sum + (item.price) * item.quantity, 0);

  return (
    <div>
      <div className="clear borderDarkBottom marginBottom10">
        <h2>Order Contents</h2>
      </div>

      <div className="flex flex-col">
        <div className="font-bold flex">
        {/* <div className="font-bold clear tableHeader padTop15 borderDarkBottom"> */}
          <div className="flex-auto">Quantity</div>
          <div className="flex-1">Item</div>
          <div className="flex-1">Remove Item</div>
          <div className="flex-1">Subtotal</div>
        </div>
        <div id="checkout_orderContents">
          {cartItems.map(item => (
            <div key={item.squareItemId} className="flex border-b border-gray-500 pt-1">
              <div className="flex-auto  justify-items-start">  {/* qty */}
                <input
                  type="text"
                  size={4}
                  value={item.quantity}
                  onChange={(e) => handleQuantityChange(item, e)}
                  className="rounded5 grayBorder xs qtyBox"
                />
              </div>
              <div className="flex-1">  {/* item name */}
                {item.name}
                <p className="ingredients"></p>
              </div>
              <div className="flex-1">  {/* remove  */}
                <button onClick={() => handleRemoveItem(item.squareItemId)}>X</button>
              </div>

              <div className="flex-1">{(item.quantity * item.price).toFixed(2)}</div>{/* item level subtotal */}
            </div>
          ))}
        </div>
      </div>

      <div className="clear itemRow textTaupe subtotalRow noBorder">
        <div className="col bold floatRight">
          Subtotal &nbsp;
          <span className="checkout_totalPrice">{totalCost.toFixed(2)}</span>
        </div>
      </div>
      <div className="clear itemRow textTaupe noBorder pad0">

      </div>
      <div>

        {/* "Let's Go, Continue!" Button */}
        <div className="marginTop20">
          <button onClick={() => {
            navigateFunction('checkout_payment');
          }}>
            Looks good, Continue!
          </button>
        </div>
      </div>
    </div>
  );
}

export default CheckoutFinalizeQuantities;
