import React from 'react';
import { MenuItem, Order, SquareItem, useGetSquareCatalogQuery } from '../../graphql/generated-types';  // Adjust the path
import MenuCategory from './MenuCategory';
import { useSession } from './SessionContext';
import { CCMenuItem } from 'src/business_logic/CCMenuItem';
import { MenuItemFactory } from 'src/business_logic/MenuItemFactory';

function MenuCategories() {
  const { loading, error, data } = useGetSquareCatalogQuery();
  const { session, setSession } = useSession();
  
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  // Group items by category
  const groupedItems: { [category: string]: any[] } = {};
  data?.getSquareCatalog?.forEach(item => {
    // convert item to CCMenuItem
    const ccMenuItem = MenuItemFactory.fromSquareItem(item as SquareItem);
    if (ccMenuItem && ccMenuItem.category) {
      if (!groupedItems[ccMenuItem.category]) {
        groupedItems[ccMenuItem.category] = [];
      }
      groupedItems[ccMenuItem.category].push(ccMenuItem);
    }
  });
  const handleAddToCart = (clickedItem : MenuItem) => {
    if (!session || !clickedItem) {
      return;
    }
    const updatedMenuItems: Array<MenuItem> = new Array<MenuItem>();
    const orderMenuItems = session?.order?.menuItems || [];

    // let itemToDadd
    orderMenuItems.length && orderMenuItems.forEach(val => updatedMenuItems.push(Object.assign({}, val)));
      //find the preCartMenuItem in the updatedMenuItems
      let updatedMenuItem = updatedMenuItems?.find((updatedItem) => updatedItem.squareItemId === clickedItem.squareItemId);
      if (updatedMenuItem) {
        updatedMenuItem.quantity += 1;
      }
      else {
        clickedItem.quantity = 1;
        updatedMenuItems.push(clickedItem);
      }

    if (session) {
      setSession({
        ...session,
        order: {
          ...session.order,
          menuItems: updatedMenuItems as [MenuItem],
        } as Order
      });
    }
    console.log('updatedMenuItems :', updatedMenuItems);
    console.log('session.order.menuItems:', session?.order?.menuItems);
  };
  
  return (
    <div>
      <div><h1>Add additional items to your cart</h1></div>
      {Object.keys(groupedItems).map(category => (
        <MenuCategory key={category} category={category} items={groupedItems[category]} addItemToCart={handleAddToCart} />
      ))}
    </div>
  );
}

export default MenuCategories;
