import { Disclosure } from '@headlessui/react';
import SRLogo from "../assets/SR_Logo_circular2.png";
import { Link, Outlet } from 'react-router-dom';
import { useSession } from './CateringHelper/SessionContext';
// Import your components for different pages

type navigationItem = {
  name: string;
  href: string;
  current: boolean;
}

const navigation = [
  { name: 'Our Food', href: '../styles', current: true },
  { name: 'Ordering', href: '../tictactoe', current: false },
  { name: 'Catering Helper', href: 'catshell', current: false },
  { name: 'Hours & Location', href: '#', current: false },
  { name: 'About Us', href: '#', current: false },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function AppShell() {
  const { session } = useSession();

  const createLinkWithSession = (path: string) => {
    // Append sessionId to the path if available
    let sessionId = session?.id;
    return sessionId ? `${path}?sessionId=${sessionId}` : path;
  };
  const handleNavigationClicked = (item: navigationItem) => {
    let currentNavLink = navigation.find(nav => nav.current);

    if (currentNavLink) {
      currentNavLink.current = false;
    }
    item.current = true;
  }

  return (
    <div className="min-h-screen flex flex-col"> {/* Change to flex container */}
      <Disclosure as="nav" className="fixed top-0 z-10 w-full border-b border-gray-300 bg-header-texture">
        {({ open }) => (
          <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex h-16 justify-between">
                <div className="flex">
                  <div className="flex flex-shrink-0 items-center">
                    <img
                      className="hidden mt-4 h-24 lg:block"
                      src={SRLogo}
                      alt="Your Company"
                    />
                  </div>
                  {/* <div className="flex flex-col"> */}
                  <div className="sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                    {navigation.map((item: navigationItem) => (
                      <Link
                        key={item.name}
                        onClick={() => handleNavigationClicked(item)}
                        to={createLinkWithSession(item.href)}
                        className={classNames(
                          item.current
                            ? 'border-red-800 border-b-4 text-gray-900'
                            : 'border-transparent text-gray-600 hover:border-gray-300 hover:text-gray-700',
                          'inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium'
                        )}
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  {/* Show the navigation item name, aligned in large white text with an bg-orange-500 that spans the width of the screen */}
                </div>
              </div>

            </div>
            <div className="w-full   bg-orange-500">
              <div className="text-2xl mx-auto max-w-7xl  text-white pl-36">{navigation.find(nav => nav.current)?.name}</div>
            </div>
          </>
        )}
      </Disclosure>

      <div className="">
        <main className="flex-1 pt-[96px] w-full bg-white z-20"> {/* Add padding top */}
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            {/* Outlet for rendering nested routes */}
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
}
